/*----------------------------------------------------
　コンポーネント：アコーディオン
----------------------------------------------------*/
.accordion {
    &.light_gray {
        box-shadow: none;
        .accordion-heading {
            background-color: #F2F4F8;
            color: #0F294D;
            border: 1px solid rgba(#0F294D,.1);
            &.small {
                @media(min-width: 992px) {
                    min-height: 50px;
                }
            }
        }
        .accordion-content {
            border-left: 1px solid rgba(#0F294D,.1);
            border-right: 1px solid rgba(#0F294D,.1);
            border-bottom: 1px solid rgba(#0F294D,.1);
        }
    }
    .accordion-heading {
        position: relative;
        background-color: #E1E8F4;
        color: #0F294D;
        font-size: 16px;
        font-weight: bold;
        padding: 16px 50px 16px 24px;
        @media(min-width: 992px) {
            font-size: 18px;
        }   

        .MuiAccordionSummary-content {
            margin-top: 0;
            margin-bottom: 0;
        }

        //icon
        &:before {
            content: "";
            display: inline-block;
            position: absolute;
            right: 25px;
            width: 2px;
            height: 16px;
            background-color: #1A3268;
            transition: .2s ease-in-out;
        }
        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            right: 25px;
            width: 2px;
            height: 16px;
            background-color: #1A3268;
            transform: rotate(90deg);
        }

        //active
        &.Mui-expanded {
            &:before {
                transform: rotate(90deg);
                transition: .2s ease-in-out;
            }
        }
    }

    .accordion-content {
        padding: 16px;
        @media(min-width: 992px) {
            padding: 40px 48px;
        }

        &.xsmall {
            @media(min-width: 992px) {
                padding: 24px;
            }
        }

        &.small {
            padding: 24px 16px;
            @media(min-width: 992px) {
                padding: 32px 24px;
            }
        }
    }
}


/*アコーディオンbox*/
.accordion-total {
    border: 1px solid #e64437;
    border-radius: 5px;
    overflow: hidden;
}
.accordion-total .MuiAccordionDetails-root {
    padding: 0 10px 16px;
    @media(min-width: 992px) {
        padding: 0 40px;
    }
}
.accordion-total .accordion-total-heading {
    background-color: #e64437;
    color: #fff;
    font-weight: bold;
    min-height: 42px;
    @media(min-width: 992px) {
        min-height: 48px;
        transition: .3s;
        &:hover {
            transition: .3s;
            opacity: .8;
        }
    }

    //active時
    &.MuiAccordionSummary-root.Mui-expanded {
        min-height: inherit;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin-top: 9px;
        margin-bottom: 9px;
        @media(min-width: 992px) {
            margin-top: 12px;
            margin-bottom: 12px;
        }
    }
}
.accordion-total.MuiAccordion-root.Mui-expanded {
    margin-top: 0;
}
.icon-accodion {
    position: relative;
    top: 2px;
    margin-left: 15px;
}
.icon-accodion:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 2px;
    height: calc(1em + 2px);
    background-color: #fff;
    transition: .3s;
}
.icon-accodion:after {
    content: "";
    display: inline-block;
    width: 2px;
    height: calc(1em + 2px);
    transform: rotate(90deg);
    background-color: #fff;
}

.accordion-total-heading .MuiAccordionSummary-content {
    display: block;
    width: 100%;
    text-align: center;
    margin: 8px 0;
    @media(min-width: 992px) {
        margin: 12px 0;
    }
}


/*開閉*/
.Mui-expanded .icon-accodion:before {
    transition: .3s;
    transform: rotate(90deg);
}

/*アコーディオン内*/
.accordion-total-content {
    width: 100%;
}

/*アコーディオン詳細*/




/* 検索アコーディオン　*/
.searchbox-wrap .accordion-search {
    box-shadow: none;

    //active時
    &.Mui-expanded {
        box-shadow: none;
    }
    .accordion-search-content {
        width: 100%;
    }
    .searchbox-content-top,
    .searchbox-content-bottom {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .searchbox-content-top {
        padding-bottom: 16px;
        @media(min-width: 992px) {
            padding-bottom: 24px;
        }
    }
    .searchbox-content-bottom {
        @media(min-width: 992px) {
            padding-bottom: 40px;
        }
    }

    .accordion-search-heading {
        display: flex;
        justify-content: center;
        position: relative;
        text-align: center;
        padding-right: 50px;
        color: #1A3268;
        @media(min-width: 992px) {
            transition: .3s;
            &:hover {
                transition: .3s;
                opacity: .7;
            }
        }
        .MuiAccordionSummary-content {
            display: inline-block;
            margin-top: 16px;
            margin-bottom: 12px;
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            @media(min-width: 992px) {
                margin-top: 24px;
                margin-bottom: 24px;
                font-size: 18px;
            }
        }
        
        .accodion-icon-wrap {
            position: absolute;
            right: 20px;
            top:50%;
            transform: translateY(-50%);
        }
        .icon-accodion {
            position: relative;
            top: 2px;
            margin-left: 15px;
        }
        .icon-accodion:before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 1px;
            width: 2px;
            height: calc(.8em + 2px);
            background-color: #1A3268;
            transition: .3s;
            @media (min-width: 992px) {
                height: calc(.9em + 2px);
                top: -2px;
            }

        }
        .icon-accodion:after {
            content: "";
            display: inline-block;
            width: 2px;
            height: calc(.8em + 2px);
            transform: rotate(90deg);
            background-color: #1A3268;
            @media (min-width: 992px) {
                height: calc(.9em + 2px);
            }
        }
    }

    .accordion-search-button {
        padding-top: 24px;
        padding-bottom: 20px;
        border-top: 1px solid rgba(#0F294D, .1);
        @media (min-width: 992px) {
            padding-top: 40px;
            padding-bottom: 32px;
        }
    }
}


//ラジオボタン　アコーディオン
.accordion-radio {
    padding: 0 16px 0;
    margin-bottom: 24px;
    box-shadow: none !important;
    border-radius: 4px;
    &:before {
        display: none;
    }
    &.Mui-expanded {
        padding-bottom: 24px;
        @media (min-width: 992px) {
            padding-bottom: 40px;
        }
    }
    @media (min-width: 992px) {
        padding: 0 48px 16px;
    }
    .accordion-radio-heading {
        padding: 24px 0;
        
        &.Mui-expanded {
            border-bottom: 1px solid rgba(#0F294D, .1);
        }
        @media (min-width: 992px) {
            padding: 50px 0 32px;
        }
    }

    .accordion-radio-content {
        padding: 16px 0;
        @media (min-width: 992px) {
            padding: 30px 0;
        }
    }

    .MuiAccordionSummary-content {
        margin-top: 0;
        margin-bottom: 0;
        &.Mui-expanded {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}


//スケジュール用アコーディオン
.accordion-schedule {
    box-shadow: none !important;
    &.box-shadow {
        box-shadow: 0 4px 6px rgba(0,0,0,.2) !important;
    }
    .accordion-schedule-heading {
        position: sticky;
        top: 0;
        z-index: 10;
        // position: relative;
        padding: 16px 50px 16px 16px;
        border-left: 2px solid #108CF7;
        background-color: #E1E8F4;
        color: #0F294D;
        font-size: 16px;
        font-weight: bold;
        @media (min-width: 992px) {
            padding: 16px 50px 16px 24px;
            transition: .3s;
            font-size: 18px;
            &:hover {
                transition: .3s;
                opacity: .8;
            }
        }
        &::after {
            content: "";
            position: absolute;
            right: 11px;
            background-image: url("../../img/share/icon/icon_arrow_bottom_circle-accordion.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 1.5em;
            height: 1.5em;
            @media (min-width: 992px) {
                right: 23px;
            }
        }
        &.Mui-expanded {
            min-height: 58px;
        }
        .MuiAccordionSummary-content {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .accordion-schedule-content {
        border-left: 2px solid #108CF7;
        padding: 24px 16px;
        @media (min-width: 992px) {
            padding: 22px;
        }

        &.large {
            @media (min-width: 992px) {
                padding-top: 30px;
            }
        }

        &.slider {
            padding: 0;
        }
    }
}


//-----------------------------------------
//アコーディオン：宿泊プラン用
//-----------------------------------------
.accordion-stay {
    .accordion-stay-heading {
        background-color: #E1E8F4;
        color: #0169D8;
        font-size: 14px;
        font-weight: bold;
    }
    .accordion-stay-detail {
        height: 0;
        overflow: hidden;
        transition: .3s;
        &.active {
            height: auto;
            transition: .3s;
        }
    }

    .accordion-stay-button {
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
        text-align: center;
        background-color: #E1E8F4;
        color: #0169D8;
        font-size: 14px;
        font-weight: bold;

        .accordion-stay-icon {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 1px;
                right: .5em;
                height: 1em;
                width: 2px;
                transform: rotate(90deg);
                background-color: #0169D8;
            }
            &:after {
                content: "";
                position: absolute;
                top: 1px;
                right: .5em;
                height: 1em;
                width: 2px;
                background-color: #0169D8;
                transition: .3s;
            }

            &.active {
                // &::before {
                //     transform: rotate(90deg);
                //     transition: .3s;
                // }
                &::after {
                    transform: rotate(90deg);
                    transition: .3s;
                }
            }
        }
    }
}


.icon-accodion-toggle-wrap {
    position: absolute;
}
.icon-accodion-toggle {
    position: relative;
    top: 3px;
    margin-left: 10px;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.icon-accodion-toggle:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 2px;
    height: 66%;
    background-color: #455873;
    transition: .3s;
}
.icon-accodion-toggle:after {
    content: "";
    display: inline-block;
    width: 2px;
    height: 66%;
    background-color: #455873;
    transform: rotate(90deg);
    transition: .3s;
}

// 背景：水色
.icon-accodion-toggle.bg-glay {
    background-color: #455873;

    &:before,
    &:after {
        background-color: #fff;
    }
}

.Mui-expanded .icon-accodion-toggle:before {
    transform: rotate(90deg);
}
