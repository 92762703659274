/*----------------------------------------------------
　コンポーネント：アイコン
----------------------------------------------------*/
.icon-blank {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-blank.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
}

.icon-blank-white {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-blank-white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
}

.icon-call-blue {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_call_blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
}

.icon-search_orange-circle {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_arrow_search_circle_orange.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-top-arrow-blue {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_arrow_top_blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: .5em;
}

.icon-left-arrow-blue {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_arrow_top_blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    width: 1em;
    height: .5em;
}

.icon-left-arrow-light_blue {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_arrow_left_light_blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-left_arrow {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_arrow_left_small.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    position: relative;
    top: 2px;
}

.icon-search-black {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_search-black.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    position: relative;
    top: 2px;
}

.icon-flag-orange {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-flag-orange.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-alert-white {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-alert-white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-contact_circle {
    display: inline-block;
    position: relative;
    background-color:#F2F4F8;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    vertical-align: middle;
    &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-image: url("../../img/share/icon/icon_contact.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 1em;
        height: 1em;
        vertical-align: middle;
    }
}

.icon-flight-circle {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-flight-gray.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-flag-circle {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-flag-gray.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-sleep-circle {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-sleep-gray.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-bad-circle-black {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-bad-circle-black.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-right-arrow-circle-blue {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_arrow_right_circle_small.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-right-arrow {
    display: inline-block;
    width: 1em;
    height: 1em;
    background: url('../../img/share/icon/icon-flight-right-arrow.svg') center center / contain no-repeat;
}

.icon-right-arrow-simple {
    display: inline-block;
    width: 1em;
    height: 1em;
    background: url('../../img/share/icon/icon-arrow-right-simple.svg') center center / contain no-repeat;
}

.icon-possible {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_possible.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.icon-impossible {
    display: inline-block;
    background-image: url("../../img/share/icon/icon_ impossible.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-modal-close-navy {
    display: inline-block;
    background-image: url("../../img/share/icon/icon-close-navy.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-bar {
    display: inline-block;
    background: #8592A6;
    width: 1em;
    height: 1px;
}
