
/*-----------------------------------------
* タグ初期値
-----------------------------------------*/
main {
  //min-heightが100vhになるように調整
  flex: 1;
}
p {
  color: #455873;
  font-size: 14px;
  @media(min-width: 992px) {
    font-size: 16px;
  }
}
a {
  color: #108CF7;
  @media(min-width: 992px) {
    transition: .3s;
    &:hover {
      transition: .3s;
      opacity: .8;
    }
  }
}
ul {
  padding-left: 0;
  list-style-type: none;
}


/*-----------------------------------------
* テーマ
-----------------------------------------*/
.theme-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F2F4F8;
}

/*-----------------------------------------
* 見出し
-----------------------------------------*/
.heading-2 {
  margin-top: 40px;
  margin-bottom: 18px;
  font-size: 20px;
  font-weight: bold;
  color: #0F294D;
  @media(min-width: 992px) {
    margin-top: 56px;
    margin-bottom: 36px;
    font-size: 26px;
  }
}

.heading-3 {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
  color: #0F294D;
  @media(min-width: 992px) {
    font-size: 18px;
  }
}
.heading-3-small {
  font-size: 16px;
  font-weight: bold;
  color: #0F294D;
  @media(min-width: 992px) {
    font-size: 18px;
  }
}


/*-----------------------------------------
* font-size
-----------------------------------------*/
.fz-10 {font-size: 10px;}
.fz-11 {font-size: 11px;}
.fz-12 {font-size: 12px;}
.fz-13 {font-size: 13px;}
.fz-14 {font-size: 14px;}
.fz-15 {font-size: 15px;}
.fz-16 {font-size: 16px;}
.fz-18 {font-size: 18px;}
.fz-20 {font-size: 20px;}
.fz-24 {font-size: 24px;}
.fz-26 {font-size: 26px;}
.fz-28 {font-size: 28px;}
.fz-30 {font-size: 30px;}
.fz-32 {font-size: 32px;}
.fz-36 {font-size: 36px;}

@media(min-width:768px) {
  .fz-10-sm {font-size: 10px;}
  .fz-12-sm {font-size: 12px;}
  .fz-13-sm {font-size: 13px;}
  .fz-14-sm {font-size: 14px;}
  .fz-15-sm {font-size: 15px;}
  .fz-16-sm {font-size: 16px;}
  .fz-18-sm {font-size: 18px;}
  .fz-20-sm {font-size: 20px;}
  .fz-22-sm {font-size: 22px;}
  .fz-24-sm {font-size: 24px;}
}

@media(min-width:992px) {
  .fz-10-md {font-size: 10px;}
  .fz-11-md {font-size: 11px;}
  .fz-12-md {font-size: 12px;}
  .fz-13-md {font-size: 13px;}
  .fz-14-md {font-size: 14px;}
  .fz-15-md {font-size: 15px;}
  .fz-16-md {font-size: 16px;}
  .fz-18-md {font-size: 18px;}
  .fz-20-md {font-size: 20px;}
  .fz-22-md {font-size: 22px;}
  .fz-24-md {font-size: 24px;}
  .fz-26-md {font-size: 26px;}
}

@media(min-width:1200px) {
  .fz-10-lg {font-size: 10px;}
  .fz-12-lg {font-size: 12px;}
  .fz-13-lg {font-size: 13px;}
  .fz-14-lg {font-size: 14px;}
  .fz-15-lg {font-size: 15px;}
  .fz-16-lg {font-size: 16px;}
  .fz-18-lg {font-size: 18px;}
  .fz-20-lg {font-size: 20px;}
  .fz-22-lg {font-size: 22px;}
  .fz-24-lg {font-size: 24px;}
}


/*-----------------------------------------
* 余白
-----------------------------------------*/
/* margin-top */
.mt-0 {margin-top: 0px;}
.mt-4 {margin-top: 4px;}
.mt-5 {margin-top: 5px;}
.mt-8 {margin-top: 8px;}
.mt-10 {margin-top: 10px;}
.mt-12 {margin-top: 12px;}
.mt-16 {margin-top: 16px;}
.mt-20 {margin-top: 20px;}
.mt-24 {margin-top: 24px;}
.mt-32 {margin-top: 32px;}
.mt-40 {margin-top: 40px;}
.mt-48 {margin-top: 48px;}
.mt-50 {margin-top: 50px;}
.mt-64 {margin-top: 64px;}
.mt-100 {margin-top: 100px;}

/* margin-bottom */
.mb-0 {margin-bottom: 0;}
.mb-2 {margin-bottom: 2px;}
.mb-4 {margin-bottom: 4px;}
.mb-5 {margin-bottom: 5px;}
.mb-8 {margin-bottom: 8px;}
.mb-10 {margin-bottom: 10px;}
.mb-12 {margin-bottom: 12px;}
.mb-14 {margin-bottom: 14px;}
.mb-16 {margin-bottom: 16px;}
.mb-18 {margin-bottom: 18px;}
.mb-20 {margin-bottom: 20px;}
.mb-24 {margin-bottom: 24px;}
.mb-32 {margin-bottom: 32px;}
.mb-40 {margin-bottom: 40px;}
.mb-50 {margin-bottom: 50px;}


/* margin-left */
.ml-0 {margin-left: 0;}
.ml-4 {margin-left: 4px;}
.ml-5 {margin-left: 5px;}
.ml-8 {margin-left: 8px;}
.ml-10 {margin-left: 10px;}
.ml-12 {margin-left: 12px;}
.ml-16 {margin-left: 16px;}
.ml-20 {margin-left: 20px;}
.ml-24 {margin-left: 24px;}

/* margin-right */
.mr-0 {margin-right: 0;}
.mr-4 {margin-right: 4px;}
.mr-5 {margin-right: 5px;}
.mr-8 {margin-right: 8px;}
.mr-10 {margin-right: 10px;}
.mr-12 {margin-right: 12px;}
.mr-16 {margin-right: 16px;}
.mr-20 {margin-right: 20px;}
.mr-24 {margin-right: 24px;}


/* padding */
.p-10 {padding: 10px;}
.p-16 {padding: 16px;}
.p-20 {padding: 20px;}
.p-24 {padding: 24px;}
.p-30 {padding: 30px;}

/* padding-top */
.pt-0 {padding-top: 0px;}
.pt-6 {padding-top: 6px;}
.pt-10 {padding-top: 10px;}
.pt-16 {padding-top: 16px;}
.pt-20 {padding-top: 20px;}
.pt-24 {padding-top: 24px;}
.pt-30 {padding-top: 30px;}
.pt-32 {padding-top: 32px;}
.pt-40 {padding-top: 40px;}
.pt-50 {padding-top: 50px;}
.pt-60 {padding-top: 60px;}
.pt-68 {padding-top: 68px;}
.pt-80 {padding-top: 80px;}
.pt-100 {padding-top: 100px;}

/* padding-bottom */
.pb-0 {padding-bottom: 0px;}
.pb-8 {padding-bottom: 8px;}
.pb-10 {padding-bottom: 10px;}
.pb-16 {padding-bottom: 16px;}
.pb-20 {padding-bottom: 20px;}
.pb-24 {padding-bottom: 24px;}
.pb-30 {padding-bottom: 30px;}
.pb-32 {padding-bottom: 32px;}
.pb-40 {padding-bottom: 40px;}
.pb-50 {padding-bottom: 50px;}
.pb-60 {padding-bottom: 60px;}
.pb-70 {padding-bottom: 70px;}
.pb-90 {padding-bottom: 90px;}
.pb-100 {padding-bottom: 100px;}

/* padding-right */
.pr-6 {padding-right: 6px;}
.pr-8 {padding-right: 8px;}
.pr-10 {padding-right: 10px;}
.pr-12 {padding-right: 12px;}
.pr-14 {padding-right: 14px;}
.pr-16 {padding-right: 16px;}
.pr-20 {padding-right: 20px;}
.pr-24 {padding-right: 24px;}

/* padding-left */
.pl-6 {padding-left: 6px;}
.pl-8 {padding-left: 8px;}
.pl-10 {padding-left: 10px;}
.pl-12 {padding-left: 12px;}
.pl-16 {padding-left: 16px;}
.pl-20 {padding-left: 20px;}

@media(min-width:768px) {
  /* margin-top */
  .mt-0-sm {margin-top: 0px;}
  .mt-5-sm {margin-top: 5px;}
  .mt-8-sm {margin-top: 8px;}
  .mt-10-sm {margin-top: 10px;}
  .mt-16-sm {margin-top: 16px;}
  .mt-20-sm {margin-top: 20px;}
  .mt-24-sm {margin-top: 24px;}
  .mt-32-sm {margin-top: 32px;}
  .mt-40-sm {margin-top: 40px;}
  .mt-50-sm {margin-top: 50px;}
  .mt-64-sm {margin-top: 64px;}
  .mt-100-sm {margin-top: 100px;}

  /* margin-bottom */
  .mb-0-sm {margin-bottom: 0;}
  .mb-5-sm {margin-bottom: 5px;}
  .mb-8-sm {margin-bottom: 8px;}
  .mb-10-sm {margin-bottom: 10px;}
  .mb-16-sm {margin-bottom: 16px;}
  .mb-20-sm {margin-bottom: 20px;}
  .mb-24-sm {margin-bottom: 24px;}
  .mb-32-sm {margin-bottom: 32px;}
  .mb-40-sm {margin-bottom: 40px;}


  /* margin-left */
  .ml-0-sm {margin-left: 0;}
  .ml-5-sm {margin-left: 5px;}
  .ml-8-sm {margin-left: 8px;}
  .ml-10-sm {margin-left: 10px;}
  .ml-12-sm {margin-left: 12px;}
  .ml-16-sm {margin-left: 16px;}
  .ml-24-sm {margin-left: 24px;}
  .ml-20-sm {margin-left: 20px;}
  .ml-30-sm {margin-left: 30px;}
  .ml-40-sm {margin-left: 40px;}
  .ml-50-sm {margin-left: 50px;}

  /* margin-right */
  .mr-0-sm {margin-right: 0;}
  .mr-5-sm {margin-right: 5px;}
  .mr-8-sm {margin-right: 8px;}
  .mr-10-sm {margin-right: 10px;}
  .mr-12-sm {margin-right: 12px;}
  .mr-16-sm {margin-right: 16px;}
  .mr-20-sm {margin-right: 20px;}
  .mr-24-sm {margin-right: 24px;}
  .mr-30-sm {margin-right: 30px;}
  .mr-40-sm {margin-right: 40px;}
  .mr-50-sm {margin-right: 50px;}

  /* padding-top */
  .pt-30-md {padding-top: 30px;}
  .pt-50-sm {padding-top: 50px;}

  /* padding-bottom */
  .pb-10-sm {padding-bottom: 10px;}
  .pb-16-sm {padding-bottom: 16px;}
  .pb-24-sm {padding-bottom: 24px;}
  .pb-30-sm {padding-bottom: 30px;}
}

@media(min-width:992px) {
  /* margin-top */
  .mt-0-md {margin-top: 0px;}
  .mt-5-md {margin-top: 5px;}
  .mt-8-md {margin-top: 8px;}
  .mt-10-md {margin-top: 10px;}
  .mt-12-md {margin-top: 12px;}
  .mt-16-md {margin-top: 16px;}
  .mt-20-md {margin-top: 20px;}
  .mt-24-md {margin-top: 24px;}
  .mt-40-md {margin-top: 40px;}
  .mt-32-md {margin-top: 32px;}
  .mt-48-md {margin-top: 48px;}
  .mt-50-md {margin-top: 50px;}
  .mt-64-md {margin-top: 64px;}
  .mt-100-md {margin-top: 100px;}

  /* margin-bottom */
  .mb-0-md {margin-bottom: 0;}
  .mb-5-md {margin-bottom: 5px;}
  .mb-8-md {margin-bottom: 8px;}
  .mb-10-md {margin-bottom: 10px;}
  .mb-12-md {margin-bottom: 12px;}
  .mb-14-md {margin-bottom: 14px;}
  .mb-16-md {margin-bottom: 16px;}
  .mb-20-md {margin-bottom: 20px;}
  .mb-24-md {margin-bottom: 24px;}
  .mb-32-md {margin-bottom: 32px;}
  .mb-40-md {margin-bottom: 40px;}
  .mb-48-md {margin-bottom: 48px;}


  /* margin-left */
  .ml-0-md {margin-left: 0;}
  .ml-2-md {margin-left: 2px;}
  .ml-5-md {margin-left: 5px;}
  .ml-8-md {margin-left: 8px;}
  .ml-10-md {margin-left: 10px;}
  .ml-12-md {margin-left: 12px;}
  .ml-16-md {margin-left: 16px;}
  .ml-20-md {margin-left: 20px;}
  .ml-24-md {margin-left: 24px;}
  .ml-30-md {margin-left: 30px;}
  .ml-32-md {margin-left: 32px;}
  .ml-40-md {margin-left: 40px;}
  .ml-50-md {margin-left: 50px;}


  /* margin-right */
  .mr-0-md {margin-right: 0;}
  .mr-2-md {margin-right: 2px;}
  .mr-5-md {margin-right: 5px;}
  .mr-8-md {margin-right: 8px;}
  .mr-10-md {margin-right: 10px;}
  .mr-12-md {margin-right: 12px;}
  .mr-16-md {margin-right: 16px;}
  .mr-20-md {margin-right: 20px;}
  .mr-24-md {margin-right: 24px;}
  .mr-30-md {margin-right: 30px;}
  .mr-32-md {margin-right: 32px;}
  .mr-40-md {margin-right: 40px;}
  .mr-50-md {margin-right: 50px;}

  /* padding */
  .p-12-md {padding: 12px;}

  /* padding-top */
  .pt-16-md {padding-top: 16px;}
  .pt-30-md {padding-top: 30px;}
  .pt-32-md {padding-top: 32px;}
  .pt-40-md {padding-top: 40px;}
  .pt-48-md {padding-top: 48px;}
  .pt-50-md {padding-top: 50px;}
  .pt-56-md {padding-top: 56px;}
  .pt-60-md {padding-top: 60px;}
  .pt-80-md {padding-top: 80px;}
  .pt-100-md {padding-top: 100px;}

  /* padding-bottom */
  .pb-10-md {padding-bottom: 10px;}
  .pb-16-md {padding-bottom: 16px;}
  .pb-24-md {padding-bottom: 24px;}
  .pb-30-md {padding-bottom: 30px;}
  .pb-32-md {padding-bottom: 32px;}
  .pb-40-md {padding-bottom: 40px;}
  .pb-48-md {padding-bottom: 48px;}
  .pb-100-md {padding-bottom: 100px;}

  /* padding-right */
  .pr-2-md {padding-right: 2px;}
  .pr-6-md {padding-right: 6px;}
  .pr-8-md {padding-right: 8px;}
  .pr-12-md {padding-right: 12px;}
  .pr-14-md {padding-right: 14px;}
  .pr-16-md {padding-right: 16px;}
  .pr-20-md {padding-right: 20px;}
  .pr-24-md {padding-right: 24px;}
  .pr-32-md {padding-right: 32px;}
  .pr-40-md {padding-right: 40px;}

  /* padding-left */
  .pl-2-md {padding-left: 2px;}
  .pl-6-md {padding-left: 6px;}
  .pl-8-md {padding-left: 8px;}
  .pl-12-md {padding-left: 12px;}
  .pl-16-md {padding-left: 16px;}
  .pl-20-md {padding-left: 20px;}
  .pl-24-md {padding-left: 24px;}
  .pl-30-md {padding-left: 30px;}
  .pl-32-md {padding-left: 32px;}
  .pl-40-md {padding-left: 40px;}
}

/*-----------------------------------------
* コンテンツ幅
-----------------------------------------*/
.wrap-content-1200 {
    width: 100%;
    max-width: 1230px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right:15px;
  }

  /*幅*/
  //per
  .w-10per {width: 10%;}
  .w-15per {width: 15%;}
  .w-20per {width: 20%;}
  .w-25per {width: 25%;}
  .w-30per {width: 30%;}
  .w-40per {width: 40%;}
  .w-45per {width: 45%;}
  .w-50per {width: 50%;}
  .w-55per {width: 55%;}
  .w-60per {width: 60%;}
  .w-70per {width: 70%;}
  .w-80per {width: 80%;}
  .w-90per {width: 90%;}
  .w-100per {width: 100%;}
  @media(min-width:992px) {
    .w-10per-md {width: 10% !important;}
    .w-15per-md {width: 15% !important;}
    .w-20per-md {width: 20% !important;}
    .w-30per-md {width: 30% !important;}
    .w-40per-md {width: 40% !important;}
    .w-50per-md {width: 50% !important;}
    .w-55per-md {width: 55% !important;}
    .w-60per-md {width: 60% !important;}
    .w-70per-md {width: 70% !important;}
    .w-80per-md {width: 80% !important;}
    .w-90per-md {width: 90% !important;}
    .w-100per-md {width: 100% !important;}
  }


  .w-50 {width: 50px;}
  .w-55 {width: 55px;}
  .w-60 {width: 60px;}
  .w-70{width: 70px;}
  .w-80{width: 80px;}
  .w-90{width: 90px;}
  .w-95{width: 95px;}
  .w-100 {width: 100px;}
  .w-110 {width: 110px;}
  .w-120 {width: 120px;}
  .w-130 {width: 130px;}
  .w-140 {width: 140px;}
  .w-150 {width: 150px;}
  .w-170 {width: 170px;}
  .w-200 {width: 200px;}
  .w-250 {width: 250px;}
  .w-270 {width: 270px;}
  .w-300 {width: 300px;}
  .w-350 {width: 350px;}
  .w-400 {width: 400px;}
  @media(min-width:992px) {
    .w-100-md {width: 100px;}
    .w-110-md {width: 110px;}
    .w-120-md {width: 120px;}
    .w-130-md {width: 130px;}
    .w-140-md {width: 140px;}
    .w-150-md {width: 150px;}
    .w-165-md {width: 165px;}
    .w-170-md {width: 170px;}
    .w-175-md {width: 175px;}
    .w-180-md {width: 180px;}
    .w-190-md {width: 190px;}
    .w-200-md {width: 200px;}
    .w-250-md {width: 250px;}
    .w-270-md {width: 270px;}
    .w-300-md {width: 300px;}
    .w-350-md {width: 350px;}
    .w-400-md {width: 400px;}

    .mxw-200-md {max-width: 200px;}
  }

  /*高さ*/
  .h-45 {height: 45px;}

  /*行間*/
  .lh-1 {line-height: 1;}
  .lh-1_25 {line-height: 1.25;}
  .lh-1_4 {line-height: 1.4;}
  .lh-1_5 {line-height: 1.5;}
  .lh-1_75 {line-height: 1.75;}
  @media(min-width:992px) {
    .lh-1-md {line-height: 1;}
    .lh-1_25-md {line-height: 1.25;}
    .lh-1_4-md {line-height: 1.4;}
    .lh-1_5-md {line-height: 1.5;}
    .lh-1_75-md {line-height: 1.75;}
  }

  /*罫線*/
  .bdr-r-gray {border-right: 1px solid rgba(#0F294D, 0.1);}

  /*その他*/
  .p-r {position: relative;}
  .d-b {display: block;}
  .d-ib {display: inline-block;}
  .d-n {display: none;}
  .d-f{display: flex;}
  .jc-c{justify-content: center;}
  .jc-sb{justify-content: space-between;}
  .jc-fe{justify-content: flex-end;}
  .ai-c {align-items: center;}
  .ai-s {align-items: flex-start;}
  .ai-e {align-items: flex-end;}
  .fd-c{flex-direction: column;}
  .fd-r{flex-direction: row;}
  .fw-w{flex-wrap: wrap;}
  .fx-sh{flex: 0 0 auto;}//flex-shrink:0
  .fx-1{flex: 1;}//flex:1 伸長
  .fxw-w{flex-wrap: wrap;}
  .fw-b {font-weight: bold;}
  .fw-n {font-weight: normal;}
  .ta-l {text-align: left;}
  .ta-r {text-align: right;}
  .ta-c {text-align: center;}
  .c-red {color: #e64437;}
  .c-dark_red {color: #C7302F;}
  .c-blue {color:#0059B9;}
  .c-navy {color:#0F294D;}
  .c-dark_blue {color: #003E92;}
  .c-dark_blue2 {color: #0F294D;}
  .c-light_gray {color: #8592A6;}
  .c-blue_gray {color: #455873;}
  .c-orange {color: #F87C00;}
  .w-a {width: auto;}
  .bgc-white {background-color: #fff;}
  .bgc-gray {background-color: #F2F4F8;}
  .border-bottom-gray {border-bottom: 1px solid rgba(#0F294D,.1);}
  .ov-h{overflow: hidden;}
  .ml-a {margin-left: auto;}
  .mr-a {margin-right: auto;}
  .ws-nw {white-space: nowrap;}
  .lst-lower-latin {list-style-type: lower-latin;}

  @media(min-width:768px) {
    .d-n-sm {display: none;}
    .d-b-sm {display: block;}
    .d-ib-sm {display: inline-block;}
    .d-f-sm {display: flex;}
    .jc-c-sm{justify-content: center;}
    .jc-sb-sm{justify-content: space-between;}
    .ai-c-sm {align-items: center;}
    .ai-s-sm {align-items: flex-start;}
    .ai-e-sm {align-items: flex-end;}
    .fd-c-sm{flex-direction: column;}
    .fd-r-sm{flex-direction: row;}
    .fw-w-sm{flex-wrap: wrap;}
    .ta-c-sm {text-align: center;}
    .ta-r-sm {text-align: right;}
    .ta-l-sm {text-align: left;}
    .w-a-sm {width: auto;}
  }
  @media(min-width:992px) {
    .d-n-md {display: none;}
    .d-b-md {display: block;}
    .d-ib-md {display: inline-block;}
    .d-f-md {display: flex;}
    .jc-c-md{justify-content: center;}
    .jc-sb-md {justify-content: space-between;}
    .jc-fe-md {justify-content: flex-end;}
    .ai-c-md {align-items: center;}
    .ai-s-md {align-items: flex-start;}
    .ai-e-md {align-items: flex-end;}
    .fd-c-md{flex-direction: column;}
    .fd-r-md{flex-direction: row;}
    .fw-w-md{flex-wrap: wrap;}
    .ta-c-md {text-align: center;}
    .ta-r-md {text-align: right;}
    .w-a-md {width: auto;}
    .bl-gray-md {border-left: 1px solid #E2E4EA;}
    .ml-a-md {margin-left: auto;}
    .mr-a-md {margin-right: auto;}
  }
  