//--------------------------------------------------
//  ライブラリ：react-loading-overlay
//--------------------------------------------------
._loading_overlay_overlay {
    z-index: 50 !important;
    background-color: rgb(65,88,117) !important;
}

.loading-hotel_hand {
    ._loading_overlay_wrapper {
        position: relative;
        max-height: 100vh;
    }
    ._loading_overlay_content {
        position: absolute;
        top: -50%;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 100vh;
        background-color: rgb(65,88,117) !important;
    }
    ._loading_overlay_spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // background-image: url("../../img/share/icon_loading.png");
    }
}

.loading-small-height {
    &._loading_overlay_wrapper--active {
        margin-top: 24px;
        height: 200px;
        overflow: hidden;
    }
}