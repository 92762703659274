/*----------------------------------------------------
　コンポーネント：ボタン
----------------------------------------------------*/
.button-blue-small {
    display: inline-block;
    padding: 4px 8px;
    background-color: #0F294D;
    color: #fff;
    border-radius: 6px;
    text-decoration: none;
    font-size: 13px;
    font-weight: bold;
    border: none;

    &.block {
        display: block;
    }

    &.fw-n{
        font-weight: normal;
    }
    &.fz-n{
        font-size: 14px;
        @media(min-width:992px){
            font-size: 16px;
        }
    }
    @media(min-width:992px) {
        transition: .3s;
        &:hover {
            opacity: .8;
            transition: .3s;
        }
    }
}

.button-blue-form_button {
    @extend .button-blue-small;
    padding: 0 45px;
    height: 45px;
    line-height: 45px;
}

.button-blue-form_button-small {
    @extend .button-blue-small;
    padding: 0 15px;
    height: 45px;
    line-height: 45px;
}

.button-medium-light_blue {
    @extend .button-blue-small;
    background-color: #1A3268;
    padding: 8px 15px;
    box-shadow: 0 3px 6px rgba(#000000 , .16);
    @media(min-width:992px) {
        transition: .3s;
        &:hover {
            box-shadow: 0 5px 6px rgba(#000000 , .32);
        }
    }
    &.padding-large {
        padding: 12px 16px;
    }
    &.large {
        @media(min-width:992px) {
            font-size: 16px;
        }
    }

    &.fz-l{
        font-size: 18px;
        height: 51px;
    }
}

.button-medium-orange {
    @extend .button-blue-small;
    background-color: #F85400;
    padding: 8px 15px;
    box-shadow: 0 3px 6px rgba(#000000, .16);

    @media(min-width:992px) {
        transition: .3s;

        &:hover {
            box-shadow: 0 5px 6px rgba(#000000, .32);
        }
    }

    &.padding-large {
        padding: 12px 16px;
    }

    &.large {
        @media(min-width:992px) {
            font-size: 16px;
        }
    }

    &.fz-l {
        font-size: 18px;
        height: 51px;
    }
}

//無効ボタン
.button-medium-disabled {
    @extend .button-blue-small;
    background-color: #DCE0EA;
    color: #7F95B7;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    border: none;
    text-align: center;
    @media(min-width:992px) {
        &:hover {
            opacity: 1;
        }
    }
}

//ボーダー
.button-border-small {
    border: 1px solid #1A3268;
    color: #1A3268;
    background-color: #fff;
    box-shadow: none;
    display: inline-block;
    padding: 4px 8px;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    @media(max-width:374px) {
        font-size: 11px;
    }
    @media(min-width:992px) {
        padding: 5px 10px;
        font-size: 12px;
        transition: .3s;
        &:hover {
            box-shadow: none;
            opacity: .8;
            transition: .3s;
        }
    }

    &.block {
        display: block;
    }
}

.button-border-small-blue {
    @extend .button-border-small;
    font-size: 12px;
    color: #0169D8;
    border: 1px solid #E2E4EA;

    &.medium {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 16px 10px;
    }
}

.button-dialog-border-small-blue {
    @extend .button-border-small;
    color: #0169D8 !important;
    border: 1px solid #E2E4EA !important;
    padding: 8px 16px !important;
    font-size: 14px;
}

.button-dialog-small-blue {
    @extend .button-border-small;
    color: #fff !important;
    background-color: #1A73E8 !important;
    border: 1px solid #1A73E8 !important;
    padding: 8px 16px !important;
    font-size: 14px;
}


//medium
.button-small-border_gray_blue {
    border: 1px solid #F2F4F8;
    background-color: #fff;
    color: #0169D8;
    box-shadow: none;
    display: inline-block;
    padding: 10px 16px;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    border: none;
    @media(max-width:374px) {
        font-size: 11px;
    }
    @media(min-width:992px) {
        &:hover {
            box-shadow: none;
            // opacity: .7;
        }
    }

    &.block {
        display: block;
    }
}

//ボーダー
.button-border-medium {
    border: 1px solid #1A3268;
    color: #1A3268;
    background-color: #fff;
    box-shadow: none;
    display: inline-block;
    padding: 10px 8px 8px;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    @media(max-width:374px) {
        font-size: 11px;
    }
    @media(min-width:992px) {
        padding: 10px 16px 8px;
        font-size: 14px;
        transition: .3s;
        &:hover {
            box-shadow: none;
            opacity: .7;
            transition: .3s;
        }
    }

    &.block {
        display: block;
    }


    //無効
    &.disable {
        background-color: #EAEEF2;
        color: #A9B4C6;
        border: none;
        pointer-events: none;
    }
}

.button-medium-red {
    @extend .button-border-medium;
    background-color: #e64437;
    color: #fff;
    border: 1px solid #e64437;
}

//Largeサイズ
//ボタンベース
%button-large {
    display: inline-block;
    padding: 12px 17px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    border: none;

    &.block {
        display: block;
    }
}

.button-large-red {
    @extend %button-large;
    background-color: #e64437;
    color: #fff;
    &:focus,
    &:active,
    &:hover {
        color: #fff;
    }
    @media(min-width:992px) {
        transition: .3s;
        &:hover {
            color: #fff;
            transition: .3s;
            background: rgba(#e64437,.7);
        }
    }
}
.button-large-red-icon {
    @extend %button-large;
    position: relative;
    padding-right: 32px;
    background-color: #e64437;
    color: #fff;
    &:focus,
    &:active,
    &:hover {
        color: #fff;
    }
    &::after {
        content: "";
        position: absolute;
        right: 5px;
        top: 50%;
        width: 1em;
        height: 1em;
        transform: translateY(-50%);
        background-image: url("../../img/share/icon/icon_arrow_right_small.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }
    @media(min-width:992px) {
        transition: .3s;
        &:hover {
            color: #fff;
            transition: .3s;
            background: rgba(#e64437,.7);
        }
    }
}

.button-large-blue {
    @extend %button-large;
    background-color: #0F294D;
    color: #fff;
    &:focus,
    &:active,
    &:hover {
        color: #fff;
    }
    @media(min-width:992px) {
        transition: .3s;
        &:hover {
            color: #fff;
            transition: .3s;
            background: rgba(#0F294D,.7);
        }
    }
}

//ボーダー
.button-border-large {
    border: 1px solid #1A3268;
    color: #1A3268;
    background-color: #fff;
    box-shadow: none;
    display: inline-block;
    padding: 10px 11px 8px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    @media(max-width:374px) {
        font-size: 11px;
    }
    @media(min-width:992px) {
        padding: 10px 11px 8px;
        font-size: 16px;
        transition: .3s;
        &:hover {
            box-shadow: none;
            opacity: .7;
            transition: .3s;
        }
    }

    &.block {
        display: block;
    }
}



//ボタンテキストリンク
.button-text-link {
    border: none;
    background-color: transparent;
    color: #455873;
    text-decoration: underline;
    transition: .3s;
    &:hover {
        transition: .3s;
        opacity: .7;
    }
}


//透明ボタン(アイコンのみの場合)
.button-clear {
    border: none;
    background-color: transparent;
}
.button-clear-blue {
    border: none;
    background-color: transparent;
    color: #108CF7;

    &.p-0 {
        padding: 0;
    }

    @media(min-width:992px) {
        transition: .3s;
        &:hover {
            transition: .3s;
            opacity: .8;
        }
    }
}

.button-clear-blue-underline {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: #108CF7;

    &.p-0 {
        padding: 0;
    }

    @media(min-width:992px) {
        transition: .3s;
        &:hover {
            transition: .3s;
            opacity: .8;
            text-decoration: none;
        }
    }
}

//ボタンを可変
.button-variable {
    height: auto;
    line-height: 1.5;
    padding: 10px 10px 8px;

    &.sp-large {
        padding-top: 14px;
        padding-bottom: 12px;
        
    }
    &.sp-medium {
        padding-top: 10px;
        padding-bottom: 8px;
        
    }
}