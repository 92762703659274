/*----------------------------------------------------
　コンポーネント：ボックス
----------------------------------------------------*/
.box-blue {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    border: 1px solid #0059B9;
    color: #0059B9;
    background-color: #E7F2FD;
    @media(min-width: 992px) {
        padding: 32px;
    }
}

.box-red {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    border: 1px solid #C7302F;
    color: #C7302F;
    background-color: #FEF5F1;
    @media(min-width: 992px) {
        padding: 32px;
    }
}

.box-light-red {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    background-color: #FEF5F1;
    @media(min-width: 992px) {
        padding: 32px;
    }

    &.small {
        padding: 16px;
        @media(min-width: 992px) {
            padding: 24px;
        }
    }
}

.box-light-gray {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    background-color: #F2F4F8;
    @media(min-width: 992px) {
        padding: 32px;
    }

    &.xsmall {
        padding: 10px;
        @media(min-width: 992px) {
            padding: 12px;
        }
    }
    &.small {
        padding: 10px;
        @media(min-width: 992px) {
            padding: 20px;
        }
    }

    &.side-small {
        padding: 16px 10px;
        @media(min-width: 992px) {
            padding: 20px 20px 11px;
        }
    }

    &.medium_small {
        padding: 12px;
    }

    &.medium {
        @media(min-width: 992px) {
            padding: 24px;
        }
    }

    &.large-md {
        padding: 16px 8px;
        @media(min-width: 992px) {
            padding: 24px 40px;
        }
    }
}

.box-white {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    background-color: #ffffff;
    color: #455873;
    @media(min-width: 992px) {
        padding: 32px;
    }

    &.small-pc {
        padding: 20px 16px;
        @media(min-width: 992px) {
            padding: 24px;
        }
    }

    &.medium {
        padding: 10px;
        @media(min-width: 992px) {
            padding: 20px;
        }
    }

    &.large {
        padding: 24px;
        @media(min-width: 992px) {
            padding: 32px;
        }
    }

    &.fixed-padding {
        padding: 16px 16px 30px;
        @media(min-width: 992px) {
            padding: 16px 40px 24px;
        }
    }

    &.radius-none {
        border-radius: 0;
    }
}

.box-light_navy {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    background-color: #E1E8F4;
    color: #455873;
    @media(min-width: 992px) {
        padding: 32px;
    }
    &.xsmall {
        padding: 10px;
        @media(min-width: 992px) {
            padding: 12px;
        }
    }
    &.small {
        padding: 10px;
        @media(min-width: 992px) {
            padding: 20px;
        }
    }
    &.medium {
        @media(min-width: 992px) {
            padding: 24px;
        }
    }
    &.radius-none {
        border-radius: 0;
    }
    &.radius-bottom {
        border-radius: 0 0 4p 4px;
    }
}

.box-navy {
    .box-navy-heading {
        padding: 16px;
        border-radius: 5px 5px 0 0;
        line-height: 1.5;
        background-color: #455873;
        color: #fff;
        @media(min-width: 992px) {
            padding: 32px;
        }

        &.small {
            @media(min-width: 992px) {
                padding: 16px;
            }
        }
    }
    .box-navy-content {
        padding: 16px 0 0;
        @media(min-width: 992px) {
            padding: 32px 0 0;
        }

        &.small {
            @media(min-width: 992px) {
                padding: 24px 0 0;
            }
        }
    }    
}

.box-small-light-red {
    padding: 10px;
    border-radius: 5px;
    line-height: 1.5;
    background-color: #FEF5F1;
    @media(min-width: 992px) {
        padding: 20px;
    }
}

.box-border-red {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    border: 1px solid #C7302F;
    background-color: #fff;
    @media(min-width: 992px) {
        padding: 32px;
    }
}

.box-border-blue {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    border: 1px solid #0059B9;
    background-color: #E7F2FD;
    @media(min-width: 992px) {
        padding: 32px;
    }

    &.small {
        @media(min-width: 992px) {
            padding: 16px;
        }
    }

    &.icon-alert {
        position: relative;
        padding-left: 40px;
        &:before {
            content: "";
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("../../img/share/icon/icon_exclamation_blue.svg");
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;
            width: 1em;
            height: 1em;
        }
    }
}

.box-freespace {
    .box-freespace-item {
        margin-bottom: 8px;
        @media(min-width: 992px) {
            margin-bottom: 16px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.box-infomation-small {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    border: 1px solid #013E80;
    background-color: #F2F4F8;
    @media(min-width: 992px) {
        padding: 24px;
    }
    .icon-infomation {
        position: relative;
        top: 2px;
        display: inline-block;
        margin-right: 8px;
        background-image: url("../../img/share/icon/icon_info.svg");
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        width: 18px;
        height: 18px;
        @media(min-width: 992px) {
            top: 1px;
        }
    }
}

.box-infomation-white-small {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    border: 1px solid #013E80;
    background-color: #fff;
    @media(min-width: 992px) {
        padding: 24px;
    }
    .icon-infomation {
        position: relative;
        top: 2px;
        display: inline-block;
        margin-right: 8px;
        background-image: url("../../img/share/icon/icon_info.svg");
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        width: 18px;
        height: 18px;
        @media(min-width: 992px) {
            top: 1px;
        }
    }
}


.box-alert-red-small {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    border: 1px solid #C7302F;
    background-color: #FEF5F1;
    @media(min-width: 992px) {
        padding: 16px;
    }

    &.icon-alert {
        position: relative;
        padding-left: 35px;
        &:before {
            content: "";
            position: absolute;
            left: 15px;
            top: 18px;
            // transform: translateY(-50%);
            background-image: url("../../img/share/icon/icon_exclamation_red.svg");
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;
            width: 1em;
            height: 1em;
            @media(min-width: 992px) {
                top: 20px;
            }
        }
    }
}

.box-alert-red-medium {
    padding: 16px;
    border-radius: 5px;
    line-height: 1.5;
    border: 1px solid #C7302F;
    background-color: #FEF5F1;
    @media(min-width: 992px) {
        padding: 22px 24px;
    }

    &.icon-alert {
        position: relative;
        padding-left: 35px;
        @media(min-width: 992px) {
            padding-left: 44px;
        }
        &:before {
            content: "";
            position: absolute;
            left: 15px;
            top: 19px;
            // transform: translateY(-50%);
            background-image: url("../../img/share/icon/icon_exclamation_red.svg");
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;
            width: 1em;
            height: 1em;
            @media(min-width: 992px) {
                top: 27px;
                left: 24px;
            }
        }
    }
}

//ボーダーボックス：太線
.box-border-gray-bold {
    padding: 24px 16px;
    border: 4px solid rgba(#000,.16);
    border-radius: 4px;
    @media(min-width:992px){
        padding:48px;
    }
}

//ボックスリンク
.box-link_blue {
    // display: inline-block;
    border: 2px solid #1A3268;
    padding: 30px 16px;
    border-radius: 5px;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #0F294D;
    text-decoration: none;
    &:after {
        content: "";
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 13px;
        height: 13px;
        background-image: url("../../img/share/icon/icon_arrow_right_circle_small.svg");
        background-size: contain;
        background-repeat: no-repeat;
    }
    @media(min-width:992px){
        padding: 32px 16px;
        transition: .3s;
        &:hover {
            // background-color: $backgrond_color-pale_blue;
            transition: .3s;
            color: #0059B9;
        }
    }
}

.box-link_light_blue {
    // display: inline-block;
    border: 2px solid #1A3268;
    padding: 30px 16px;
    border-radius: 5px;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #0F294D;
    text-decoration: none;
    &:after {
        content: "";
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 13px;
        height: 13px;
        background-image: url("../../img/share/icon/icon_arrow_right_circle_small.svg");
        background-size: contain;
        background-repeat: no-repeat;
    }
    @media(min-width:992px){
        padding: 32px 16px;
        transition: .3s;
        &:hover {
            // background-color: $backgrond_color-pale_blue;
            transition: .3s;
            color: #0059B9;
        }
    }

    &.small {
        padding: 24px 16px;
    }
}

// ボックス：区切り線
.box-separator {
	.box-separator-item {
		padding-top: 16px;
		padding-bottom: 16px;
		border-top: 2px solid #F2F4F8;
        &:last-child {
            padding-bottom: 0;
        }
        &.bt-n {
            border-top: none;
        }
        &.pt-n {
            padding-top: none;
        }

        &.pc-large {
            @media(min-width: 992px) {
                padding-top: 29px;
                padding-bottom: 29px;
            }
            &:last-child {
                @media(min-width: 992px) {
                    padding-bottom: 0;
                }
            }
        }
	}
}

//下区切り線
.box-separator_bottom {
	.box-separator_bottom-item {
		padding-top: 16px;
		padding-bottom: 16px;
		border-bottom: 2px solid #F2F4F8;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
	}
}

//下区切り線(小サイズ)
.box-separator_bottom-border_small {
	.box-separator_bottom-border_small-item {
		padding-top: 24px;
		padding-bottom: 24px;
		border-bottom: 1px solid #F2F4F8;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
	}
}

//下区切り線(余白中)
.box-separator_bottom-medium {
	.box-separator_bottom-medium-item {
		padding-top: 24px;
		padding-bottom: 15px;
		border-bottom: 2px solid #F2F4F8;
        @media(min-width: 992px) {
            padding-bottom: 22px;
        }
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
        }
	}
}

//下区切り線(余白大)
.box-separator_bottom-large {
	.box-separator_bottom-large-item {
		padding-top: 24px;
		padding-bottom: 24px;
		border-bottom: 2px solid #F2F4F8;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
        }
	}

    //ボーダーなし
    .box-separator_bottom-large-item_no_bdr {
		padding-top: 20px;
		padding-bottom: 20px;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
        }
	}
}

//ボックス見出しあり
.box-heading-blue {
    .box-heading-blue-text {
        padding: 10px 16px;
        border-radius: 6px 6px 0 0 ;
        background-color: #E1E8F4;
        color: #003E92;
        font-size: 18px;
        font-weight: bold;
        @media(min-width: 992px) {
            padding: 16px 32px;

        }
    }
    .box-heading-blue-content {
        padding: 30px 16px;
        border-bottom: 1px solid rgba(#0F294D,.1);
        border-left: 1px solid rgba(#0F294D,.1);
        border-right: 1px solid rgba(#0F294D,.1);
        @media(min-width: 992px) {
            padding: 36px 40px;
        }
    }
}


//合計ボックス
.box-total {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 18px 16px 14px;
    border-radius: 4px 4px 0 0;
    border-top: 2px solid #e64437;
    border-left: 2px solid #e64437;
    border-right: 2px solid #e64437;
    background-color: #FEF5F1;
    @media(min-width: 992px) {
        padding: 20px 30px;
    }

    &.box {
        border-radius: 4px;
        border-bottom: 2px solid #e64437;
    }
}
.box-list-dl {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dotted;
    margin: 0;
    padding: 15px 0;
}
.box-list .box-list-dl:first-child {
    padding-top: 0;
}
.box-list .box-list-dl:last-child {
    border-bottom: none;
    padding-bottom: 0;
}
.reserve_detail-form-pay {
    margin-top: 50px;
}
.box-total-pay {
    font-size: 28px;
    font-weight: bold;
    color: #e64437;
    &.small {
        font-size: 24px;
        @media(min-width: 992px) {
            font-size: 32px;
        }
    }
}
.box-total-heading {
    font-size: 16px;
    font-weight: bold;
    color: #0F294D;
    @media(min-width: 992px) {
        font-size: 18px;
    }

    &.small {
        font-size: 14px;
        @media(min-width: 992px) {
            font-size: 18px;
        }
    }
}
.box-total-credit {
    margin-top: 5px;
    width: 100%;
    text-align: right;
    font-size: 13px;
    @media(min-width: 992px) {
        font-size: 14px;
    }
    // .box-total-credit-shield {}
}

.box-plan-bt_gray {
    background-color: #fff;
    border-top: 2px solid #455873;
    border-radius: 4px;
    .box-plan-bt_gray-list {   
        .box-plan-bt_gray-list-item {
            padding: 20px 16px 16px;
            border-bottom: 1px solid #E2E4EA;
            @media(min-width: 992px) {
                padding: 24px;
            }
            &:last-child {
                border-bottom: none;
            }
            
            &.p-0 {
                padding: 0;
                @media(min-width: 992px) {
                    padding: 0;
                }
            }
            &.large {
                padding: 24px 16px;
                @media(min-width: 992px) {
                    padding: 24px 24px 18px;
                }
            }
        }
    }

    .box-plan-bt_gray-list-item-content {
        display: flex;
        flex-wrap: wrap;
        @media(min-width: 992px) {
            flex-wrap: inherit;
        }
    }
    .box-plan-bt_gray-list-item-content-description {
        display: flex;
        margin-bottom: 16px;
        @media(min-width: 992px) {
            flex: 1;
            margin-bottom: 0;
            margin-right: 24px;
        }
    }
    .box-plan-bt_gray-list-item-content-description-thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 100%;
        max-width: 112px;
        max-height: 112px;
        overflow: hidden;
        @media(min-width: 992px) {
            max-width: 140px;
            max-height: 140px;
            margin-right: 24px;
        }
        &.large {
            @media(min-width: 992px) {
                max-width: 192px;
                max-height: 192px;
                .box-plan-bt_gray-list-item-content-description-text {
                    width: calc(100% - 192px);
                }
            }
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            max-width: none;
            aspect-ratio: 1 / 1;
        }
    }
    .box-plan-bt_gray-list-item-content-description-text {
        font-size: 16px;
        font-weight: bold;
        width: calc(100% - 112px);
    }

    .box-plan-bt_gray-list-item-content-status {
        display: flex;
        align-items: center;
        background-color: #F2F4F8;
        border-radius: 4px;
        padding: 15px;
        width: 100%;
        @media(min-width: 992px) {
            padding: 24px 16px;
            width: 185px;
        }
    }
}

//合計ボックス（簡易版）
.box-total-short {
    border: 2px solid #e64437;
    border-radius: 4px;
    .box-total-short-sub_total {
        padding: 20px 12px;
        border-bottom: 2px solid #e64437;
        background-color: #fff;
        @media(min-width: 992px) {
            padding: 32px 40px;
        }
        &.small {
            padding: 8px 12px 16px;
            @media(min-width: 992px) {
                padding: 24px 40px;
            } 
            .box-total-short-sub_total-item {
                padding-top: 12px;
                padding-bottom: 10px;
                @media(min-width: 992px) {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
        .box-total-short-sub_total-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dotted #E2E4EA;
            padding-top: 16px;
            padding-bottom: 16px;
            @media(min-width: 992px) {
                padding-top: 24px;
                padding-bottom: 24px;
            }
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
    .box-total-short-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 16px;
        background-color: #FEF5F1;
        @media(min-width: 992px) {
            padding: 20px 30px;
        }
        .box-total-short-total-heading {
            font-size: 16px;
            font-weight: bold;
            color: #0F294D;
            @media(min-width: 992px) {
                font-size: 18px;
            }
        }
        .box-total-short-total-pay {
            font-size: 28px;
            font-weight: bold;
            color: #e64437;
        }
    }
}

//ボックスプラン
.box-plan {
    padding: 16px;
    border-bottom: 1px solid #E2E4EA;
    @media(min-width: 992px) {
        padding: 24px;
    }
    &:last-child {
        border-bottom: none;
    }
}
.box-plan-content {
    .box-plan-content-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
        @media(min-width: 992px) {
            flex-wrap: inherit;
        }
    }
}

.box-plan-content-item-description {
    display: flex;
    margin-bottom: 16px;
    @media(min-width: 992px) {
        flex: 1;
        margin-bottom: 0;
        margin-right: 24px;
    }
}
.box-plan-content-item-description-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 100%;
    max-width: 88px;
    max-height: 88px;
    overflow: hidden;
    @media(min-width: 992px) {
        max-width: 140px;
        max-height: 140px;
    }
    img {
        height: 100%;
        object-fit: cover;
        max-width: none;
        aspect-ratio: 1 / 1;
        width: 100%;
    }
}
.box-plan-content-item-description-text {
    font-size: 16px;
    font-weight: bold;
    flex: 1;
}

.box-plan-content-item-status {
    // display: flex;
    // align-items: center;
    background-color: #F2F4F8;
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    @media(min-width: 992px) {
        display: flex;
        align-items: center;
        padding: 24px 20px;
        width: 212px;
    }
    .box-plan-content-item-status-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(min-width: 992px) {
            display: block;
        }
    }
}


//ボックス：フライト
.box-flight {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 100px 16px 16px;
    background-color: #F2F4F8;
    border-radius: 4px;
    @media(min-width: 992px) {
        flex-wrap: nowrap;
        padding: 0 24px;
    }
    .box-flight-seat {
        display: flex;
        flex-wrap: wrap;
        padding-top: 16px;
        padding-bottom: 0;
        @media(min-width: 992px) {
            flex-wrap: nowrap;
            align-items: center;
            margin-right: 32px;
            padding-top: 24px;
            padding-bottom: 16px;
        }
        .box-flight-seat-label {
            margin-bottom: 4px;
            @media(min-width: 992px) {
                margin-bottom: 0;
            }
        }
        .box-flight-seat-detail {
            font-size: 14px;
            font-weight: bold;
            @media(min-width: 992px) {
                font-size: 16px;
            }
        }
    }
    .box-flight-time {
        padding-top: 4px;
        padding-bottom: 4px;
        @media(min-width: 992px) {
            margin-right: 16px;
            padding-top: 24px;
            padding-bottom: 16px;
            flex: 1;
        }
    }
    .box-flight-time-airline {
        display: flex;
        @media(min-width: 992px) {
            align-items: flex-end;
        }
        .box-flight-time-airline-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            max-width: 80px;
            padding-bottom: 4px;
            @media(min-width: 992px) {
                // height: 40px;
                max-width: 64px;
            }
            // img {
            //     height: 100%;
            //     // width: 100%;
            // }
        }
        .box-flight-time-airline-label {
            font-size: 11px;
            text-align: center;
            @media(min-width: 992px) {
                font-size: 12px;
            }
        }
    }
    .box-flight-time-airline-time {
        display: flex;
        .box-flight-time-airline-time-item {
            position: relative;
            padding-right: 30px;
            &::after {
                content: "";
                position: absolute;
                right: 8px;
                top:50%;
                transform: translateY(-50%);
                width: .8em;
                height: .8em;
                background-image: url("../../img/share/icon/icon-flight-right-arrow.svg");
                background-size: contain;
                background-repeat: no-repeat;
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
                &::after {
                    display: none;
                }
            }
        }
        .box-flight-time-airline-time-item-heading {
            height: 30px;
            font-size: 20px;
            font-weight: bold;
            @media(min-width: 992px) {
                height: 40px;
                font-size: 28px;
            }
        }
        .box-flight-time-airline-time-item-label {
            font-size: 11px;
            text-align: center;
            @media(min-width: 992px) {
                font-size: 12px;
            }
        }
    }
    .box-flight-detail {
        width: 100%;
        @media(min-width: 992px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 186px;
            padding: 16px 0 20px 16px;
            text-align: center;
        }
        .box-flight-detail-change {
            @media(min-width: 992px) {
                margin-bottom: 10px;
            }
        }

        .box-flight-detail-button {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            @media(min-width: 992px) {
                position: inherit;
                right: inherit;
                top: inherit;
                transform: none;
            }
        }
    }
}


//ボックス：宿泊
.box-stay {
    .box-stay-item {
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #E2E4EA;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    //キャンセルボタンがある場合はオフセット
    &.cancel-button {
        .box-stay-item {
            &:last-child {
                padding-bottom: 24px;
            }
        }
    }
}
.box-stay-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 32px;
    &.pb-none {
        padding-bottom: 0;
    }
    .box-stay-item-thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 100%;
        max-width: 112px;
        max-height: 112px;
        overflow: hidden;
        @media(min-width: 992px) {
            max-width: 140px;
            max-height: 140px;  
            margin-right: 24px;       
        }
        &.sp_small-pc_large {
            max-width: 88px;
            max-height: 88px;
            @media(min-width: 992px) {
                max-width: 160px;
                max-height: 160px; 
            }
        }
        &.small {
            max-width: 88px;
            max-height: 88px;
            @media(min-width: 992px) {
                max-width: 140px;
                max-height: 140px; 
            }
        }
        &.xmiddle {
            max-width: 92px;
            max-height: 92px;
            @media(min-width: 992px) {
                max-width: 160px;
                max-height: 160px;
            }
        }
        &.middle {
            max-width: 112px;
            max-height: 112px;
            @media(min-width: 992px) {
                max-width: 160px;
                max-height: 160px;
            }
        }
        &.large {
            @media(min-width: 992px) {
                max-width: 192px;
                max-height: 192px;
            }
        }
        img {
            object-fit: cover;
            height: 100%;
            max-width: none;
            aspect-ratio: 1 / 1;
            width: 100%;
        }
    }
    .box-stay-item-content-sp {
        width: 100%;
        @media(min-width: 992px) {
            display: none;
        }
    }
    .box-stay-item-content {
        flex: 1;
        @media(min-width: 992px) {
            margin-right: 25px;
        }
        .box-stay-item-content-area {
            .box-stay-item-content-area-list {
                display: flex;
                .box-stay-item-content-area-list-item {
                    position: relative;
                    padding-right: 16px;
                    font-size: 12px;
                    color: #455873;
                    &::after {
                        content: "・";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:last-child {
                        padding-right: 0;
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
        .box-stay-item-content-heading {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 2px;
            color: #0F294D;
            &.mb-medium {
                margin-bottom: 4px;
            }
            &.mb-large {
                margin-bottom: 10px;
            }
            &.mb-xlarge {
                margin-bottom: 16px;
            }

            &.small {
                font-size: 14px;
            }
        }
        .box-stay-item-content-label {
            margin-bottom: 8px;
            .box-stay-item-content-label-list {
                display: flex;
                .box-stay-item-content-label-list-item {
                    position: relative;
                    margin-right: 8px;
                    padding: 4px 4px;
                    line-height: 1;
                    font-size: 10px;
                    background-color: #F2F4F8;
                    color: #455873;
                    border-radius: 4px;
                    &:last-child {
                        margin-right: 0;
                    }
                }

                &.light-green {
                    .box-stay-item-content-label-list-item {
                        background-color: #E6F5F6;
                        color: #0097A7;
                    }
                }
            }
        }
        .box-stay-item-content-option {
            flex-direction: column;
            display: none;
            width: 100%;
            @media(min-width: 992px) {
                display: flex;
            }
            .box-stay-item-content-option-dl {
                display: flex;
                color: #455873;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .box-stay-item-content-option-dl-dt {
                position: relative;
                font-size: 12px;
                width: 100px;
                padding-right: 15px;
                &:after {
                    content: "：";
                    position: absolute;
                    right: 0;
                }
            }
            .box-stay-item-content-option-dl-dd {
                flex: 1;
                font-size: 12px;
            }
        }
    }
    .box-stay-item-optipn {
        margin-bottom: 13px;
        width: 100%;
        @media(min-width: 992px) {
            display: none;
        }
        .box-stay-item-content-option {
            margin-top: 12px;
            flex-direction: column;
            @media(min-width: 992px) {
                display: flex;
            }
            .box-stay-item-content-option-dl {
                display: flex;
                color: #455873;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .box-stay-item-content-option-dl-dt {
                position: relative;
                font-size: 12px;
                width: 90px;
                padding-right: 15px;
                &:after {
                    content: "：";
                    position: absolute;
                    right: 0;
                }
            }
            .box-stay-item-content-option-dl-dd {
                flex: 1;
                font-size: 12px;
            }
        }
    }
    .box-stay-item-detail {
        display: flex;
        align-items: center;
        background-color: #F2F4F8;
        border-radius: 4px;
        padding: 16px 16px 20px;
        width: 100%;
        @media(min-width: 992px) {
            padding: 24px 20px;
            width: 185px;
        }

        &.top-hotel_select {
            @media(min-width: 992px) {
                padding: 15px 20px;
            }
        }

        &.small {
            padding: 12px 16px;
            @media(min-width: 992px) {
                padding: 24px 20px;
                width: 157px;
            }
        }
        &.large {
            @media(min-width: 992px) {
                width: 215px;
            }
        }
        &.padding-large-bottom-medium {
            @media(min-width: 992px) {
                padding: 20px 20px 18px 20px;
            }
        }
    }
    .box-stay-item-cancel {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}


//ボックスオプション
.box-option {
    padding: 16px;
    border-bottom: 1px solid #E2E4EA;
    @media(min-width: 992px) {
        padding: 24px;
    }
    &:last-child {
        border-bottom: none;
    }
}
.box-option-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 32px;
    @media(min-width: 992px) {
        flex-wrap: inherit;
    }
}
.box-option-content-description {
    display: flex;
    margin-bottom: 16px;
    @media(min-width: 992px) {
        flex: 1;
        margin-bottom: 0;
        margin-right: 24px;
    }
}
.box-option-content-description-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 100%;
    max-width: 88px;
    max-height: 88px;
    overflow: hidden;
    @media(min-width: 992px) {
        max-width: 140px;
        max-height: 140px;
    }
    img {
        object-fit: cover;
        height: 100%;
        max-width: none;
        aspect-ratio: 1 / 1;
        width: 100%;
    }
}
.box-option-content-description-text {
    font-size: 16px;
    font-weight: bold;
    flex: 1;
}

.box-option-content-status {
    background-color: #F2F4F8;
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    @media(min-width: 992px) {
        padding: 24px;
        width: 207px;
    }
    .box-option-content-status-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(min-width: 992px) {
            display: block;
        }
    }
}
.box-option-cancel {
    position: absolute;
    bottom: 0;
    right: 0;
}


//ボックスオプション追加
.box-slider_sp {
    display: flex;
    overflow-x: auto;
    @media(min-width: 992px) {
        display: inline;
        overflow-x: inherit;
        width: 100%;
    }
    .box-slider_sp-item {
        // margin-right: 16px;
        position: relative;
        border-right: 3px solid rgba(#0F294D,.1);
        padding: 16px;
        width: 207px;
        flex-shrink: 0;
        @media(min-width: 992px) {
            padding: 24px 24px 32px;
            border-bottom: 4px solid rgba(#0F294D,.1);
            border-right: none;
            width: 100%;
        }
        &:last-child {
            padding-bottom: 0;
            border-right: none;
        }

        //sp時のmoreコンテンツ分をオフセット
        &:nth-last-child(2) {
            @media(min-width: 992px) {
                border-bottom: none;
            }
        }

        //sp 最後の要素
        &.more {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            @media(min-width: 992px) {
                display: none;
            }
        }

        .box-slider_sp-item-content {
            color: #0F294D;
            @media(min-width: 992px) {
                display: flex;
                flex: 1;
            }
            .box-slider_sp-item-content-thumbnail {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-right: 16px;
                width: 172px;
                height:172px;
                max-width: 172px;
                max-height: 172px;
                overflow: hidden;
                @media(min-width: 992px) {
                    margin-bottom: 0;
                    margin-right: 24px;
                }
                img {
                    object-fit: cover;
                    height: 100%;
                    max-width: none;
                    aspect-ratio: 1 / 1;
                    width: 100%;
                }
            }
        }
        .box-slider_sp-item-content-detail {
            @media(min-width: 992px) {
                flex: 1;
                position: relative;
                padding-bottom: 50px;
            }
            .box-slider_sp-item-content-detail-description {
                margin-bottom: 16px;
                font-size: 14px;
                font-weight: bold;
                height: calc(3em * 1.5);

                display: -webkit-box; //行数制限
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                @media(min-width: 992px) {
                    height: auto;
                    margin-bottom: 6px;
                    -webkit-line-clamp: 4;
                    line-height: 1.75;
                }
            }

            .box-slider_sp-item-content-detail-label {
                margin-bottom: 8px;
            }
            .box-slider_sp-item-content-detail-label-list {
                display: flex;
                flex-wrap: wrap;
                .box-slider_sp-item-content-detail-label-list-item {
                    margin-right: 4px;
                    margin-bottom: 4px;
                    padding: 4px;
                    background-color: #F2F4F8;
                    color: #455873;
                    border-radius: 4px;
                    font-size: 10px;
                    font-weight: bold;
                }
            }
            .box-slider_sp-item-content-detail-status {
                @media(min-width: 992px) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
                .box-slider_sp-item-content-detail-status-price {
                    margin-bottom: 8px;
                    text-align: right;
                    @media(min-width: 992px) {
                        margin-right: 8px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}


//ボックスレンタカー
.box-rentalcar-pickup {
    width: 100%;
    .box-rentalcar-pickup-heading {
        margin: auto;
        margin-bottom: 24px;
        width: 100%;
        max-width: 225px;
        @media(min-width: 992px) {
            margin-top: 8px;
            margin-bottom: 27px;
            max-width: 334px;
        }
    }
    .box-rentalcar-pickup-content {
        margin: auto;
        margin-bottom: 16px;
        max-width: 730px;
        width: 100%;
        @media(min-width: 992px) {
            margin-bottom: 24px;
        }
    }
}
.box-rentalcar-set {
    width: 100%;
    .box-rentalcar-set-item {
        margin-bottom: 16px;
        @media(min-width: 992px) {
            margin-bottom: 24px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.box-rentalcar-set-item-select {
    position: relative;
    padding-bottom: 32px;
    @media(min-width: 992px) {
        display: flex;
        position: relative;
    }
}
.box-rentalcar-set-item-select-detail {
    @media(min-width: 992px) {
        flex: 1;
        margin-right: 16px;
    }
}
.box-rentalcar-set-item-select-detail-car-heading,
.box-rentalcar-set-item-select-detail-optipn-heading {
    padding: 8px 16px;
    background-color: #F2F4F8;
    color: #0F294D;
    font-weight: bold;
    font-size: 14px;
    @media(min-width: 992px) {
        padding: 8px 14px;
        font-size: 16px;
    }
}
.box-rentalcar-set-item-select-detail-car-list {
    .box-rentalcar-set-item-select-detail-car-list-item {
        display: flex;
        padding: 16px 8px;
        border-bottom: 1px solid rgba(#0F294D,.1);
        @media(min-width: 992px) {
            padding: 16px;
        }
        &:last-child {
            border-bottom: none;
        }
        .box-rentalcar-set-item-select-detail-car-list-item-thumbnail {
            margin-right: 16px;
            width: 88px;
            @media(min-width: 992px) {
                width: 170px;
                margin-right: 32px;
            }
        }
        .box-rentalcar-set-item-select-detail-car-list-item-detail {    
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            flex: 1;
            @media(min-width: 992px) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }  
        .box-rentalcar-set-item-select-detail-car-list-item-detail-heading {
            @media(min-width: 992px) {
                margin-right: 16px;
            }
            .box-rentalcar-set-item-select-detail-car-list-item-detail-heading-main {
                margin-bottom: 4px;
                color: #0F294D;
                font-size: 14px;
                font-weight: bold;
                @media(min-width: 992px) {
                    font-size: 16px;
                }
            }
            .box-rentalcar-set-item-select-detail-car-list-item-detail-heading-sub {
                color: #455873;
                font-size: 12px;
            }
        }   
        .box-rentalcar-set-item-select-detail-car-list-item-detail-subtotal {
            color: #E64437;
            font-size: 16px;
            font-weight: bold;
        }
    }     
}
.box-rentalcar-set-item-select-price {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F2F4F8;
    padding: 16px;
    @media(min-width: 992px) {
        width: 185px;
    }
    .box-rentalcar-set-item-select-price-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        @media(min-width: 992px) {
            flex-direction: column;
        }
    }

    .box-rentalcar-set-item-select-price-total {
        @media(min-width: 992px) {
            margin-bottom: 10px;
        }
    }
}
.box-rentalcar-set-item-select-cancel {
    position: absolute;
    bottom: 0;
    right: 0;
}

.box-rentalcar-set-item-select-detail-optipn-list {
    .box-rentalcar-set-item-select-detail-optipn-list-item {
        display: flex;
        justify-content: space-between;
        padding: 16px 8px;
        border-bottom: 1px solid rgba(#0F294D,.1);
        &:last-child {
            border-bottom: none;
        }
    }
}

.box-separate {
    .box-separate-item {
        border-bottom: 2px solid #e7e9ed;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}
.box-separate-item .box-separate-item-block {
    border-bottom: 1px dotted #e7e9ed;
    padding-top: 16px;
    padding-bottom: 16px;
    @media(min-width: 992px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &.small {
        padding-top: 8px;
        @media(min-width: 992px) {
            padding-top: 8px;
        }
    }

    &.small-md {
        @media(min-width: 992px) {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    &.large {
        @media(min-width: 992px) {
            padding-top: 32px;
            padding-bottom: 40px;
        }
    }
}
.box-separate-item .box-separate-item-block:last-child {
    border-bottom: none;
}

.box-separate-item-block-subtotal {
    display: flex;
    align-items: center;
}

.box-separate-item-block-subtotal-heading {
    width: 50%;
}
.box-separate-item-block-subtotal-heading .box-separate-item-block-subtotal-heading-item {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    @media(min-width: 992px) {
        font-size: 16px;
    }
}
.box-separate-item-block-subtotal-quantity {
    width: 25%;
    text-align: right;
}
.box-separate-item-block-subtotal-quantity .box-separate-item-block-subtotal-quantity-item {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    @media(min-width: 992px) {
        font-size: 16px;
    }
}
.box-separate-item-block-subtotal-total {
    width: 25%;
    text-align: right;
}
.box-separate-item-block-subtotal-total .box-separate-item-block-subtotal-total-item {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: bold;
}


.box-separate-item-block-subtotal_list {
    .box-separate-item-block-subtotal_list-item {
        display: flex;
        align-items: flex-end;
        margin-bottom: 9px;
        @media(min-width: 992px) {
            margin-bottom: 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .box-separate-item-block-subtotal_list-item-label {
        width: 47%;
        font-size: 14px;
        line-height: 1.25;
        @media(min-width: 992px) {
            width: 43%;
            font-size: 16px;
        }
    }
    .box-separate-item-block-subtotal_list-item-subtotal {
        padding-right: 10px;
        width: 30%;
        font-size: 13px;
        line-height: 1.25;
        text-align: right;
        @media(min-width: 992px) {
            font-size: 16px;
        }
    }
    .box-separate-item-block-subtotal_list-total-total {
        width: 25%;
        flex: 1;
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
        text-align: right;
        @media(min-width: 992px) {
            font-size: 22px;
        }
    }
}


//フライト選択用
.box-flight-select {
    .box-flight-select-heading {
        display: flex;
        margin-bottom: 8px;
        @media(min-width: 992px) {
            margin-bottom: 16px;
        }
        .box-flight-select-heading-label {
            display: flex;
            margin-right: 10px;
            .box-flight-select-heading-label-item {
                margin-right: 4px;
                padding: 5px 4px;
                border-radius: 4px;
                line-height: 1;
                font-size: 13px;
                @media(min-width: 992px) {
                    font-size: 14px;
                }
                &.outbound {
                    background-color: #003E92;
                    color: #fff;
                }
                &.connecting {
                    background-color: #FF9800;
                    color: #fff;
                }
            }
        }
        .box-flight-select-heading-text {
            font-size: 14px;
            font-weight: bold;
            @media(min-width: 992px) {
                font-size: 16px;
            }
        }
    }
    .box-flight-select-detail {
        display: flex;
        flex: 1;
        .box-flight-select-detail-schedule {
            margin-right: 16px;
            flex: 1;
            .box-flight-select-detail-schedule-airline {
                display: flex;
                .box-flight-select-detail-schedule-airline-logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 25px;
                    max-width: 64px;
                    padding-bottom: 4px;
                    @media(min-width: 992px) {
                        height: 30px;
                        max-width: 60px;
                        width: 80px;
                    }
                    // img {
                    //     height: 100%;
                    // }
                }
                .box-flight-select-detail-schedule-airline-label {
                    font-size: 11px;
                    text-align: center;
                    white-space: nowrap; //便番号は改行をさせない
                    @media(min-width: 992px) {
                        font-size: 12px;
                    }
                }
                .box-flight-select-detail-schedule-airline-time {
                    display: flex;
                    .box-flight-select-detail-schedule-airline-time-item {
                        position: relative;
                        padding-right: 30px;
                        &::after {
                            content: "";
                            position: absolute;
                            right: 8px;
                            top:50%;
                            transform: translateY(-50%);
                            width: .8em;
                            height: .8em;
                            background-image: url("../../img/share/icon/icon-flight-right-arrow.svg");
                            background-size: contain;
                            background-repeat: no-repeat;
                        }
                        &:last-child {
                            margin-right: 0;
                            padding-right: 0;
                            &::after {
                                display: none;
                            }
                        }
                    }
                    .box-flight-select-detail-schedule-airline-time-item-heading {
                        height: 25px;
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 1;
                        @media(min-width: 992px) {
                            height: 30px;
                            font-size: 28px;
                        }
                    }
                    .box-flight-select-detail-schedule-airline-time-item-label {
                        font-size: 11px;
                        text-align: center;
                        @media(min-width: 992px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        
    }
    .box-flight-select-detail-status {
        max-width: 100px;
        // padding: 16px;
        @media(min-width: 992px) {
            width: 100px;
        }
    }
}




//------------------------------------------------------
// ボックスフライト情報
//------------------------------------------------------
.box_select-flight-schedule-title {
	padding: 15px 12px;
	background-color: #455873;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0 0;
    @media(min-width:992px) {
        padding: 14px 24px;
        padding-right: 16px;
    }
}
.box_select-flight-schedule-date {
    flex: 1;
    font-size: 13px;
    color: #fff;
	font-weight: bold;
    @media(min-width:992px) {
        font-size: 16px;
    }
}
.box_select-flight-schedule-difference {
    flex: 0 0 auto;
    width: 31.9%;
    max-width: 132px;
    padding: 3px 0;
    border-left: 1px solid rgba(#fff, 0.2);
	font-size: 10px;
	font-weight: bold;
	color: #fff;
    text-align: right;
    @media(min-width:992px) {
        font-size: 14px;
    }
}

.box_select-flight-schedule-list {
    background: #E1E8F4;
}

.box_select-flight-schedule-list-item {
    display: flex;
    position: relative;
    border-bottom: 2px solid #CBD4E3;
}
.box_select-flight-schedule-list-item-time_table {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    width: 120px;
    padding: 12px 10px 7px;
    background-color: #fff;
    color: #0F294D;
    text-align: center;
    position: relative;
    @media(min-width:992px) {
        padding: 15px 10px;
        width: 245px;
        flex-direction: row;
        align-items: center;
    }
}
.box_select-flight-schedule-list-item-time_table-list {
    @media(min-width:992px) {
        flex: 1;
        padding-left: 8px;
    }
        
    .box_select-flight-schedule-list-item-time_table-list-item {
        position: relative;
        // margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: -2px;
            right: 20px;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #CBD4E3;
            @media(min-width:992px) {
                right: 30px;
            }
        }
        &:last-child {
            margin-bottom: 0;
            &:after {
                display: none;
            }
        }
    }
}

.box_select-flight-schedule-list-item-time_table-flight_number {
    font-size: 12px;
    // margin-bottom: 5px;
    @media(min-width:992px) {
        flex: 0 0 auto;
        width: 46%;
        margin-bottom: 0;
        padding-right: 13px;
        font-size: 14px;
        text-align: right;
    }
}

//乗り継ぎ・直行便ラベル
.box_select-flight-schedule-list-item-time_table-label {
    align-self: flex-start;
    margin-top: -12px;
    margin-left: -10px;
    margin-bottom: 4px;
    padding: 0 4px;
    line-height: 15px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    background: #FF9800;
    @media(min-width:992px) {
        margin-top: 0;
        margin-left: 0;
        font-size: 12px;
        line-height: 21px;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.box_select-flight-schedule-list-item-seat {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 5px;
}
.box_select-flight-schedule-list-item-seat-content {
    width: 100%;
    height: 100%;
    min-height: 77px;
    margin: 2px 0 0 0;
    padding: 5px 8px;
    border-radius: 5px 0 0 5px;
    background-color: #fff;
    @media(min-width:992px) {
        padding: 16px;
    }
    &+.box_select-flight-schedule-list-item-seat-content {
        margin-top: 1px;
    }
    &:first-child {
        margin-top: 2px;
        @media(min-width:992px) {
            margin-top: 4px;
        }
    }
    &:last-child {
        margin-bottom: 2px;
        @media(min-width:992px) {
            margin-bottom: 4px;
        }
    }

    //選択不可
    &.invalid {
        background-color: #F2F4F8;
        color: #C7D6E8;
        .box_select-flight-schedule-list-item-seat-content-list-label {
            color: #C7D6E8;
            label {
                cursor: text;
            }

            input[type=radio] {
                display: none;

                & + label {
                    pointer-events: none;
                    &::after {
                        content: none;
                    }
                }
            }
        }
        .box_select-flight-schedule-list-item-seat-content-list-status {
            background-color: #F2F4F8;
            label {
                pointer-events: none;
            }
        }
        .box_select-flight-schedule-list-item-seat-content-list-result {
            label {
                pointer-events: none;
            }
        }
        .box_select-flight-schedule-list-item-seat-content-list-result-text {
            color: #C7D6E8;
        }
    }
}
.box_select-flight-schedule-list-item-seat-content-list {
    display: flex;
    align-items: stretch;
    height: 100%;

    &:hover {
        .box_select-flight-schedule-list-item-seat-content-list-label {
            color: #0169D8;
        }
    }
}
.box_select-flight-schedule-list-item-seat-content-list-label {
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    color: #0F294D;
    transition: 0.3s;

    //チェック中
    input[type=radio] {
        display: block;
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        margin: 0;
        width: 100%;
        height: 100%;
        background-color: transparent; //ipad対策
        border-radius: 4px;
        z-index: 1;
        cursor: pointer;
        @media all and (-ms-high-contrast: none) {
            display: none;
        }
        &:checked {
            border: 3px solid #E64437;
        }

        & + label {
            padding-left: 34px;
            z-index: inherit;
            &::before,
            &::after {
                top: 50%;
                left: 7px;
                transform: translateY(-50%);
                @media(min-width: 768px) {
                    top: 49%;
                }
            }
            &::after {
                left: 12px;
            }
        }
    }
}
.box_select-flight-schedule-list-item-seat-content-list-status {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 17.5%;
    text-align: center;
    border-radius: 5px;
    // z-index: 2;
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(#0F294D, 0.1);
    }
    &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(#0F294D, 0.1);
    }
}
.box_select-flight-schedule-list-item-seat-content-list-status-label {
    @media(min-width: 992px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.box_select-flight-schedule-list-item-seat-content-list-result {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 5px;
    width: 40%;
    max-width: 145px;
    text-align: right;
    // z-index: 5;
    @media(min-width: 375px) {
        width: 43.5%;
        max-width: 127px;
    }
    @media(min-width: 992px) {
        width: 45%;
    }
}
.box_select-flight-schedule-list-item-seat-content-list-result-label {
    font-size: 8px;
    color: #0F294D;
    @media(min-width: 992px) {
        font-size: 12px;
    }
}
.box_select-flight-schedule-list-item-seat-content-list-result-text {
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
    color: #C7302F;
    @media(min-width: 992px) {
        font-size: 22px;
    }
    @media(max-width:375px) {
        font-size: 14px;
    }

    //追加料金なし
    &.no_addition {
        font-size: 13px;
        color: #0F294D;
        @media(min-width: 992px) {
            font-size: 16px;
        }
    }
}


//------------------------------------------------------
// ボックスホテル情報
//------------------------------------------------------
.box_select-hotel{
    margin-bottom: 24px;
    @media(min-width:992px) {
        margin-bottom: 42px;
    }
}
.box_select-hotel-title {
	padding: 8px 16px;
	background-color: #455873;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
    border-radius: 5px 5px 0 0;
    @media(min-width:992px) {
        font-size: 18px;
    }

    &.second {
        border-radius: 0;
    }
}
.box_select-hotel-schedule-title {
	display: flex;
	justify-content: space-between;
	padding: 5px 10px;
	background-color: #455873;
}
.box_select-hotel-schedule-date-item {
	font-size: 12px;
	color: #fff;
	font-weight: bold;
    @media(min-width:992px) {
        font-size: 14px;
    }
}
.box_select-hotel-schedule-area-item {
    padding-left: 30px;
    padding-right: 15px;
    // border-left: 1px solid $border-white_opacity;
	font-size: 10px;
	color: #fff;
	font-weight: bold;
    @media(min-width:992px) {
        font-size: 12px;
    }
}

.box_select-hotel-schedule-list {
    background: #E1E8F4;
    border-top: 2px solid #CBD4E3;
}

.box_select-hotel-schedule-list-item {
    // display: flex;
    position: relative;
    border-bottom: 2px solid #CBD4E3;
}

.box_select-hotel-schedule-list-item-date {
    display: flex;
    flex-direction: column;
    width: 98.8%;
    margin-left: 3px;
    @media(min-width:992px) {
        width: 99.5%;
    }
}
.box_select-hotel-schedule-list-item-date-content {
    width: 100%;
    height: 100%;
    margin: 2px 0 0 0;
    border-radius: 5px 0 0 5px;
    background-color: #fff;
    
    &+.box_select-hotel-schedule-list-item-date-content {
        margin-top: 1px;
    }
    &:first-child {
        margin-top: 4px;
    }
    &:last-child {
        margin-bottom: 4px;
    }

    //選択不可
    &.invalid {
        background-color: #F2F4F8;
        color: #C7D6E8;
        pointer-events: none;
        .box_select-hotel-schedule-list-item-date-content-list-label {
            color: #C7D6E8;
            label {
                cursor: text;
            }

            input[type=radio] {
                & + label {
                    color: #A9B4C6;
                    &::after{
                        background: #F2F4F8;
                    }

                    .price {
                        color: #A9B4C6;
                    }
                }
            }
        }
        .box_select-hotel-schedule-list-item-date-content-list-label-price{
            color: #A9B4C6;
        }
        .box_select-hotel-schedule-list-item-date-content-list-result {
            color: #A9B4C6;
        }
    }
}
.box_select-hotel-schedule-list-item-date-content-list {
    position: relative;
    display: flex;
    align-items: stretch;
    height: 100%;
    width: 100%;
    min-height: 77px;
    padding: 8px 12px;
    @media(min-width:992px) {
        padding: 8px;
    }

    &:hover {
        .box_select-hotel-schedule-list-item-date-content-list-label {
            color: #0169D8;
        }
    }
}
.box_select-hotel-schedule-list-item-date-content-list-label {
    display: flex;
    align-items: center;
    width: 92%;
    color: #0F294D;
    transition: 0.3s;
    // @media(min-width: 992px) {
    //     width: 60%;
    // }

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 8px;
        bottom: 8px;
        left: 48px;
        width: 1px;
        background-color: rgba(#0F294D, 0.1);
        margin: auto;
        @media(min-width: 992px) {
            left: 78px;
        }
    }

    //チェック中
    input[type=radio] {
        &:checked {
            display: block;
            position: absolute;
            top: -3px;
            bottom: -3px;
            left: -3px;
            right: 0;
            margin: 0;
            border: 3px solid #E64437;
            background-color: transparent; //ipad対策
            border-radius: 4px;
            width: calc(100% + 3px);
            height: calc(100% + 3px);
            z-index: 1;
            @media all and (-ms-high-contrast: none) {
                display: none;
            }
            @media(min-width: 992px) {
                border: 3px solid #E64437;
                border-radius: 3px;
            }
        }

        & + label {
            font-size: 14px;
            font-weight: bold;
            padding-left: 50px;
            padding-right: 5px;
            color: #0F294D;
            @media(min-width: 992px) {
                font-size: 16px;
                padding-left: 95px;
            }

            .price {
                color: #E64437;
            }

            &::before,
            &::after {
                left: 6px;
                top: 50%;
                transform: translateY(-50%);
                @media(min-width: 992px) {
                    left: 23px;
                }
            }
            &::after{
                left: 11px;
                @media(min-width: 992px) {
                    left: 28px;
                }
            }
        }
    }
}
.box_select-hotel-schedule-list-item-date-content-list-label-price{
    display: none;
    @media(min-width: 992px) {
        display: block;
        color: #e64437;
        font-size: 20px;
        font-weight: bold;
        margin-left: 20px;
    }
}
.box_select-hotel-schedule-list-item-date-content-list-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 17%;
    text-align: center;
    border-radius: 5px;
    z-index: 2;
    @media(min-width: 992px) {
        width: 19.5%;
    }

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(#0F294D, 0.1);
    }
    &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(#0F294D, 0.1);
    }
}
.box_select-hotel-schedule-list-item-date-content-list-status-label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    color: #0F294D;
    @media(min-width: 992px) {
        flex-direction: row;
        font-size: 14px;

        .icon-possible{
            top: 1px;
        }
    }
}
.box_select-hotel-schedule-list-item-date-content-list-status-label-text{
    margin-bottom: 3px;
    @media(min-width: 992px) {
        margin-right: 10px;
        margin-bottom: 0;
    }
}
.box_select-hotel-schedule-list-item-date-content-list-result {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 5px;
    // width: 50%;
    text-align: right;
    z-index: 5;
    padding: 10px 0 10px 18px;
    @media(min-width: 992px) {
        width: 39%;
        padding: 10px 15px 10px 20px;
    }

    .select_box-default{
        select{
            padding: 8px 33px 8px 10px;
            @media(min-width: 992px) {
                padding: 6px 33px 6px 15px;
            }
        }

        &:after{
            right: 12px;
        }
    }
}
.box_select-hotel-schedule-list-item-date-content-list-result-label {
    font-size: 8px;
    color: #0F294D;
    @media(min-width: 992px) {
        font-size: 12px;
    }
}
.box_select-hotel-schedule-list-item-date-content-list-result-text {
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
    color: #C7302F;
    @media(min-width: 992px) {
        font-size: 22px;
    }
    @media(max-width:375px) {
        font-size: 14px;
    }
}

// アイコンについて
.box_select-hotel-icon-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: 992px) {
        flex-direction: row;
    }

    .box_select-hotel-icon-list-item{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E2E4EA;
        width: 100%;
        padding: 12px 0;

        @media(min-width: 992px) {
            flex: 0 0 auto;
            width: auto;
            border-right: 2px solid #E2E4EA;
            border-bottom: 0;
            padding: 0 24px;
        }

        &:first-of-type {
            padding-top: 0;
            @media(min-width: 992px) {
                padding-left: 0;
            }
        }

        &:last-of-type{
            padding-bottom: 0;
            border-bottom: 0;
            @media(min-width: 992px) {
                padding-right: 0;
                border-right: 0;
            }
        }

        span, i {
            @media(min-width: 992px) {
                flex: 0 0 auto;
            }
        }
    }

    .box_select-hotel-icon-status{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 47px;
        @media(min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
            // width: 19%;
            margin-right: 24px;
        }
    }
}




//------------------------------------------------------
// ボックストグルボタングループ
//------------------------------------------------------
.box-toggle_button {
    width: calc(100% + 8px);
    margin-top: -8px;
    flex-wrap: wrap;
    .box-toggle_button-item {
        width: calc(33.33% - 8px);
        max-width: 120px;
        min-width: 106px;
        margin-top: 8px;
        margin-right: 8px;
        padding: 5px;
        font-size: 12px;
        font-weight: bold;
        color: #7A879C;
        border: 1px solid #E2E4EA;
        border-radius: 4px;
        @media(min-width: 992px) {
            // padding: 5px 16px;
            font-size: 14px;
        }

        &.MuiToggleButtonGroup-groupedHorizontal:not(:last-child),
        &.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
            border: 1px solid #E2E4EA;
            border-radius: 4px;
            //selected
            &.Mui-selected {
                color: #0169D8;
                border-color: #0169D8;
                border: 1px solid #0169D8;
                background: #E7F2FD;
            }
        }

        //selected
        &.Mui-selected {
            color: #0169D8;
            border-color: #0169D8;
            background: #E7F2FD;
        }
    }

    &.w-a {
        .box-toggle_button-item {
            width: auto;
            max-width: inherit;
            min-width: inherit;
        }
    }
}


//キャンセルページで使用
.box-cancel_option {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    @media(min-width: 992px) {
        flex-direction: row;
        padding: 32px;
    }
    .box-cancel_option-content {
        flex: 1;
        @media(min-width: 992px) {
            margin-right: 24px;
        }

        .box-separate {
            .box-separate-item {
                &:first-child {
                    .box-separate-item-block {
                        padding-top: 0;
                    }
                }
            }
        }

        .box-cancel_option-content-dl {
            display: flex;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
            .box-cancel_option-content-dl-dt {
                position: relative;
                padding-right:10px;
                width: 80px;
                font-size: 12px;
                font-weight: bold;
                color: #455873;
                @media(min-width: 992px) {
                    width: 100px;
                    font-size: 14px;
                }
                &::after {
                    content:"：";
                    position: absolute;
                    right: 5px;
                }
            }
            .box-cancel_option-content-dl-dd {
                flex: 1;
                font-size: 12px;
                color: #455873;
                @media(min-width: 992px) {
                    font-size: 14px;
                }
            }
        }
    }
    .box-cancel_option-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 16px;
        background-color: #FEF5F1;
        @media(min-width: 992px) {
            flex-direction: column;
            justify-content: center;
            padding: 16px 24px;
        }
    }
}



//ボックスカード：レンタカー
//sp - 縦、pc - 横　区切り線
// .box-card-rentacar {}
.box-card-rentacar-item {
    border-radius: 4px;
    background: #fff;
    @media(min-width: 768px) {
        display: flex;
        padding: 33px 40px;
    }
}
.box-card-rentacar-detail {
    padding: 20px 16px;
    display: flex;
    @media(min-width: 768px) {
        flex: 1;
        padding: 0;
        align-items: center;
    }
}
.box-card-rentacar-detail-left {
    flex: 0 0 auto;
    width: 128px;
    text-align: center;
    @media(min-width: 768px) {
        width: 161px;
    }

    &.child_seat {
        width: 97px;
        @media(min-width: 768px) {
            width: 129px;
        }
    }
}
.box-card-rentacar-detail-right {
    padding-top: 8px;
    padding-left: 13px;
    @media(min-width: 768px) {
        padding-top: 0;
        padding-left: 40px;
    }
}
// .box-card-rentacar-detail-right-table {}
.box-card-rentacar-detail-right-table-item {
    font-size: 12px;
    color: #455873;
    display: flex;
    @media(min-width: 768px) {
        font-size: 14px;
    }
}
.box-card-rentacar-detail-right-table-item-heading {
    min-width: 4em;
    display: flex;
    justify-content: space-between;
    &::after {
        content: "：";
    }

    &.child_seat {
        min-width: auto;
    }
}
.box-card-rentacar-price {
    padding: 13px 16px;
    border-top: 1px solid rgba(#0F294D, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media(min-width: 768px) {
        flex: 0 0 auto;
        width: 157px;
        padding: 0;
        border-top: none;
        border-left: 1px solid rgba(#0F294D, 0.1);
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
}

.box-card-rentacar-price-right {
    padding: 13px 16px;
    border-top: 1px solid rgba(#0F294D, 0.1);
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    @media(min-width: 768px) {
        flex: 0 0 auto;
        width: 157px;
        padding: 0;
        border-top: none;
        border-left: 1px solid rgba(#0F294D, 0.1);
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
}

.box-sort {
    &.small {
        .box-sort-list {
            .box-sort-list-item {
                padding-left: 4px;
                padding-right: 4px;
            }
        }
    }
}

.box-sort-list {
    display: flex;
    padding: 8px 8px 10px;
    background-color: #fff;
    border-radius: 4px;
    .box-sort-list-item {
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid rgba(#0F294D, .1);
        line-height: 1;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            border-right: none;
        }

        .box-sort-list-item-link {
            font-size: 10px;
            font-weight: bold;
            color: #7A879C;
            transition: .3s;
            @media(min-width: 992px) {
                font-size: 11px;
            }
            &.active {
                color:#0169D8;
            }
            &:hover {
                opacity: .8;
                transition: .3s;
            }

            @media(max-width:376px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}


//--------------------------------------------------
// 宿泊アコーディオン
//--------------------------------------------------
.box-heading-navy {
    padding: 14px 24px;
    border-radius: 6px 6px 0 0;
    background-color: #455873;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    @media(min-width: 992px) {
        padding: 14px 24px 10px;
        font-size: 16px;
        font-weight: bold;
    }
}

.box-stay-list {
    .box-stay-list-item {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.box-img-label {
    display: flex;
    padding: 8px;
    justify-content: left;
    align-items: flex-start;
    align-content: flex-start;
    gap: 4px;
    flex: 1 0 0;
    flex-wrap: wrap;

    .box-img-size {
        width: 52px;
        margin: auto;
        @media(min-width:768px) {
            width: 72px;
        }
    }

    .box-img-label-text {
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 4px;
        flex: 1 0 0;
        flex-wrap: wrap;
        margin: auto 0;
        @media(min-width:478px) {
            text-align: left;
            display: inline-block;
        }
    }
}

.box-border-left-gray {
    border-left: 1px solid var(--blue-gray-blue-gray-01-border, rgba(15, 41, 77, 0.10));
}



//------------------------------------------------------
// クーポンコード入力欄
//------------------------------------------------------

.box-coupon-code {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media(min-width: 992px) {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 14px 20px;
    }

    // クーポンコード入力欄と注意書きの位置を交換する
    > :nth-child(3) {
        @media(min-width: 992px) {
            order: 4;
        }
    }

    > :nth-child(4) {
        @media(min-width: 992px) {
            order: 3;
        }
    }

    .form-input-text {
        @media(min-width: 992px) {
            // 入力部分の横幅80% + 微調整分
            width: calc(80% + 8px);
            margin-bottom: 0;
        }
    }
}

.box-coupon-code-note {
    display: block;
    color: #8592A6;
    font-size: 12px;

    @media(min-width: 992px) {
        font-size: 14px;
    }

    &::before {
        content: "※";
        font-size: 1em;
        font-family: "Noto Sans JP", sans-serif;
        margin-right: 8px;
    }
}

.box-coupon-code-button.button-medium-orange {
    display: block;
    margin-inline: auto;
    padding: 10px 52px;
    font-size: 14px;

    @media(min-width: 992px) {
        margin-inline: unset;
        margin-left: 16px;
        padding: 10px 16px;
        // ボタンの横幅20% - 余白分
        width: calc(20% - 24px);
    }
}

.box-coupon-code-button.button-border-medium {
    display: block;
    margin-inline: auto;
    margin-top: 12px;
    padding: 6px 18px;
    line-height: 1.25;
    border-radius: 6px;

    @media(min-width: 992px) {
        margin-inline: unset;
        margin-top: 0;
        margin-left: 16px;
        padding: 10px 16px;
        width: calc(20% + 16px);
    }
}

// クーポン適用後ボックス
.box-coupon-code-applied {
    width: 100%;
    @media(min-width: 992px) {
        display: flex;
        align-items: center;
    }
}

.box-coupon-code-applied.box-light-gray {
    padding: 16px 8px;

    @media(min-width: 992px) {
        padding: 16px;
    }
}

.box-coupon-code-applied-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    @media(min-width: 992px) {
        width: 80%;
        font-size: 18px;
        text-align: left;
    }
}