/*----------------------------------------------------
　コンポーネント：カウンター
----------------------------------------------------*/
.counter {
    display: flex;
    align-items: center;
    .counter-minus {
        margin-right: 20px;
        display: flex;
        align-items: center;
        .counter-minus-icon {
            width: 20px;
            height: 20px;
            padding: 0;
            border: 1px solid #0F294D;
            border-radius: 50%;
            // line-height: 1;
            background-color: #fff;
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 12px;
                height: 1px;
                background: #0F294D;
                border-radius: 2px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            //無効
            &:disabled {
                border-color: #C7D6E8;
                &::before {
                    background: #C7D6E8;
                }
            }
        }
    }

    .counter-value {
        display: flex;
        align-items: baseline;
        font-size: 18px;
        font-weight: bold;
    }

    .counter-plus {
        margin-left: 20px;
        display: flex;
        align-items: center;
        .counter-plus-icon {
            width: 22px;
            height: 22px;
            padding: 0;
            border: 1px solid #0F294D;
            border-radius: 50%;
            line-height: 1;
            background-color: #fff;
            position: relative;
            &::before,
            &::after {
                content: "";
                display: block;
                width: 12px;
                height: 1px;
                background: #0F294D;
                border-radius: 2px;
                position: absolute;
                top: 50%;
                left: 50%;
            }
            &::before {
                transform: translate(-50%, -50%);
            }
            &::after {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }
    }
}