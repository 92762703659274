/*----------------------------------------------------
　コンポーネント：リスト
----------------------------------------------------*/
//リスト横並び（性別選択時等）
.list-side {
    display: flex;
    list-style-type: none;
    padding-left: 0;
	&> li {
		margin-right: 16px;
		&:last-child {
			margin-right: 0;
		}
	}
}

//注釈リスト
.list-note {
    padding-left: 0;
	font-size: 12px;
    list-style-type: none;
    color: #0F294D;
	@media(min-width:992px){
		font-size: 14px;
	}
	&> li{
		// color: $text-color-pale_gray;
        margin-bottom: 8px;
		padding-left: 1em;
		text-indent: -1em;
		line-height: 1.5em;
		&:before {
			content: "※";
            padding-right: 0.5em;
		}
        &:last-child {
            margin-bottom: 0;
        }
	}

	&.fz-n{
		font-size: 14px;
		@media(min-width:992px){
			font-size: 16px;
		}
	}

    &.red {
        color: #C7302F;
    }
	&.gray{
		color: #455873;
	}
    &.light-gray {
        color: #8592A6;
    }
}

.list-note-text {
    padding-left: 0;
	font-size: 12px;
    list-style-type: none;
    color: #0F294D;
	@media(min-width:992px){
		font-size: 14px;
	}
	&> li{
		// color: $text-color-pale_gray;
        margin-bottom: 8px;
		padding-left: 1.75em;
		text-indent: -1.75em;
		line-height: 1.5em;
		&:before {
			content: "注:";
            padding-right: 0.5em;
		}
        &:last-child {
            margin-bottom: 0;
        }
	}

	&.fz-n{
		font-size: 14px;
		@media(min-width:992px){
			font-size: 16px;
		}
	}

    &.red {
        color: #C7302F;
    }
	&.gray{
		color: #455873;
	}
    &.light-gray {
        color: #8592A6;
    }
}

//ボックスリスト
.list-box {
    padding-left: 0;
	font-size: 12px;
    list-style-type: none;
    color: #0F294D;
	@media(min-width:992px){
		font-size: 14px;
	}
	&> li{
		// color: $text-color-pale_gray;
        margin-bottom: 8px;
		padding-left: 1em;
		text-indent: -1em;
		line-height: 1.5em;
		&:before {
			content: "■";
            padding-right: 0.5em;
		}
        &:last-child {
            margin-bottom: 0;
        }
	}

	&.list-mb-large {
		&> li{
			margin-bottom: 24px;
			@media(min-width:992px){
				margin-bottom: 8px;
			}
		}
	}

	&.large {
		&> li{
			@media(min-width:992px){
				margin-bottom: 24px;
			}
		}
	}

	&.last-mb-small {
		&> li{
			&:last-child {
				margin-bottom: 16px;
			}
		}
	}

	&.fz-n{
		font-size: 14px;
		@media(min-width:992px){
			font-size: 16px;
		}
	}

    &.red {
        color: #C7302F;
    }
	&.gray{
		color: #455873;
	}
	&.light-gray {
        color: #8592A6;
    }
}

//・リスト
.list-dot {
    padding-left: 0;
	font-size: 12px;
    list-style-type: none;
    color: #0F294D;
	@media(min-width:992px){
		font-size: 14px;
	}
	&> li{
		// color: $text-color-pale_gray;
        margin-bottom: 8px;
		padding-left: 1em;
		text-indent: -1em;
		line-height: 1.5em;
		&:before {
			content: "・";
            padding-right: 0.5em;
		}
        &:last-child {
            margin-bottom: 0;
        }
	}

	&.fz-n{
		font-size: 14px;
		@media(min-width:992px){
			font-size: 16px;
		}
	}

    &.red {
        color: #C7302F;
    }
	&.gray{
		color: #455873;
	}
	&.light-gray {
        color: #8592A6;
    }
}

.list-dot-blue_gray {
	list-style-type: none;
	& > li {
		margin-top: 4px;
		padding-left: 1em;
		text-indent: -1em;
		color: #455873;
		font-size: 12px;
		line-height: 1.5;
		@media(min-width:992px){
			font-size: 13px;
		}
		&:first-of-type {
			margin-top: 0;
		}
		&::before {
			content: "・";
		}
	}
}


//数字リスト(*1)
.list-note-number {
    padding-left: 0;
	list-style-type: none;
	counter-reset:number;
	&> li {
		margin-bottom: 8px;
		padding-left: 1.25em;
		text-indent: -1.25em;
		line-height: 1.5em;
		&:last-child {
			margin-bottom: 32px;
		}
		&:before {
			margin-right: .5em;
			counter-increment:number;
			content:"※"counter(number);
		}
	}
	&.small {
		font-size: 12px;
		@media(min-width: 992px) {
			font: 14px;
		}		
	}
    &.light-gray {
        color: #8592A6;
    }

	&.mb-0{
		&> li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

//数字リスト((1))
.list-wrap-number {
    padding-left: 0;
	list-style-type: none;
	counter-reset:number;
	&> li {
		margin-bottom: 8px;
		padding-left: 1.75em;
		text-indent: -1.75em;
		line-height: 1.5em;
		&:last-child {
			margin-bottom: 32px;
		}
		&:before {
			margin-right: .5em;
			counter-increment:number;
			content:"("counter(number)")";
		}
	}
	&.small {
		font-size: 12px;
		@media(min-width: 992px) {
			font: 14px;
		}		
	}
    &.light-gray {
        color: #8592A6;
    }

	&.mb-0{
		&> li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

//数字リスト(①)
.list-number-circle {
    padding-left: 0;
	list-style-type: none;
	counter-reset:number;
	&> li {
        position: relative;
		margin-bottom: 8px;
		// padding-left: 1.75em;
		text-indent: 0;
		line-height: 1.5em;
		&:last-child {
			margin-bottom: 32px;
		}
		&:before {
			counter-increment:number;
			content:counter(number);
            width: 1.25em;
            height: 1.25em;
            margin-right: .25em;
            // padding: .1em .39em;
            border-radius: 50%;
            border: 1px solid #455873;
			position: absolute;
			left: -1.5em;
			font-size: .85;
			top:0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&.small {
		&> li {
			font-size: 13px;
			margin-bottom: 4px;
			@media(min-width:992px){
				font-size: 14px;
			}
			&::before {
				top: 2px;
				width: 15px;
				height: 15px;
				font-size: .8em;
				line-height: 1;
				left: -1.75em;
				@media(min-width:992px){
					top: 4px;
					left: -1.65em;
				}
			}
		}
		
	}
	&.card-padding-small {
		margin-left: 17px;
		@media(min-width:992px){
			margin-left: 18px;
		}
	}
    &.gray{
		color: #455873;
	}
	&.light-gray {
        color: #8592A6;
    }

	&.mb-0{
		&> li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.list-number-circle-blue-link {
	padding-left: 0;
	list-style-type: none;
	counter-reset:number;
	&> li {
		a {
			position: relative;
			margin-bottom: 8px;
			padding-left: 2em;
			text-indent: 0;
			line-height: 1.5em;
			&:last-child {
				margin-bottom: 32px;
			}
			&:before {
				counter-increment:number;
				content:counter(number);
				width: 1.6em;
				height: 1.6em;
				border: none;
				background-color: #1A3268;
				color: #fff;
				margin-right: .25em;
				border-radius: 50%;
				border: 1px solid #455873;
				position: absolute;
				left: 0;
				font-size: .85em;
				top:50%;
				transform: translateY(-50%);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

//数字リスト(1.)
.list-number-dot {
	margin-left: 1.5em;
    padding-left: 0;
	list-style-type: none;
	counter-reset:number;
	&.last-mb-none {
		&>li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&> li {
		margin-bottom: 8px;
		line-height: 1.5em;
		&:last-child {
			margin-bottom: 32px;
		}
		&:before {
			display: inline-block;
			margin-left: -1.5em;
			width: 1.5em;
			counter-increment:number;
			content:counter(number)".";
		}
	}
	&.gray{
		color: #455873;
	}
	&.light-gray {
        color: #8592A6;
    }

	&.mb-0{
		&> li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.large {
		&> li {
			margin-bottom: 16px;
			font-size: 14px;
			@media(min-width:992px) {
				font-size: 16px;
				margin-bottom: 24px;
			}
		}
	}
}

//数字リスト(一・二)
.list-kanji {
	margin-left: 2.5em;
    padding-left: 0;
	list-style-type: none;
	counter-reset:number;
	&.last-mb-none {
		&>li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&> li {
		margin-bottom: 8px;
		line-height: 1.5em;
		&:last-child {
			margin-bottom: 32px;
		}
		&:before {
			display: inline-block;
			margin-left: -2.25em;
			width: 2.25em;
			counter-increment:number;
			content:counter(number, cjk-ideographic);
		}
	}
	&.gray{
		color: #455873;
	}
	&.light-gray {
        color: #8592A6;
    }

	&.mb-0{
		&> li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.large {
		&> li {
			margin-bottom: 16px;
			font-size: 14px;
			@media(min-width:992px) {
				font-size: 16px;
				margin-bottom: 24px;
			}
		}
	}
}

//数字リスト(イ・ロ)
.list-iroha {
	margin-left: 1.5em;
    padding-left: 0;
	list-style-type: none;
	counter-reset:number;
	&.last-mb-none {
		&>li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&> li {
		margin-bottom: 8px;
		line-height: 1.5em;
		&:last-child {
			margin-bottom: 32px;
		}
		&:before {
			display: inline-block;
			margin-left: -1.5em;
			width: 1.5em;
			counter-increment:number;
			content:counter(number, katakana-iroha);
		}
	}
	&.gray{
		color: #455873;
	}
	&.light-gray {
        color: #8592A6;
    }

	&.mb-0{
		&> li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.large {
		&> li {
			margin-bottom: 16px;
			font-size: 14px;
			@media(min-width:992px) {
				font-size: 16px;
				margin-bottom: 24px;
			}
		}
	}
}

//数字リスト(【1】)
.list-number-box {
	margin-left: 1.5em;
    padding-left: 0;
	list-style-type: none;
	counter-reset:nox_number;
	@media(min-width:992px) {
		margin-left: 2em;
	}
	&.last-mb-none {
		&>li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&> li {
		margin-bottom: 8px;
		line-height: 1.5em;
		font-size: 14px;
		@media(min-width:992px) {
			font-size: 16px;
		}
		&:last-child {
			margin-bottom: 32px;
		}
		&:before {
			display: inline-block;
			margin-left: -2.5em;
			width: 2.5em;
			counter-increment:nox_number;
			content:"【"counter(nox_number)"】";
		}
	}
	&.gray{
		color: #455873;
	}
	&.light-gray {
        color: #8592A6;
    }

	&.mb-0{
		&> li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.large {
		&> li {
			margin-bottom: 16px;
			@media(min-width:992px) {
				margin-bottom: 24px;
			}
		}
	}
}

//注釈リスト(青アイコン)
.list-alert-blue {
    padding-left: 0;
	font-size: 12px;
    list-style-type: none;
    color: #0F294D;
	@media(min-width:992px){
		font-size: 14px;
	}
	&> li{
		// color: $text-color-pale_gray;
        margin-bottom: 8px;
		padding-left: 1.5em;
		text-indent: -1.5em;
		line-height: 1.5em;
		&:before {
			content: "";
            padding-right: 1.5em;
            background-image: url("../../img/share/icon/icon_exclamation_blue.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 1em;
            height: 1em;
		}
        &:last-child {
            margin-bottom: 0;
        }
	}

    &.red {
        color: #C7302F;
    }
	&.gray{
		color: #455873;
	}
    &.light-gray {
        color: #8592A6;
    }
}


//フライト乗継ステップ
.list-flight-connecting {
	.list-flight-connecting-item {
		position: relative;
		margin-bottom: 5px;
		padding-left: 30px;
		min-height: 60px;
		&:before {
			content: "";
			position: absolute;
			left: 4px;
			top: 7px;
			width: 8px;
			height: 8px;
			background-color: #108CF7;
			border-radius: 50%;
		}
		&::after {
			content: "";
			position: absolute;
			top: 18px;
			left: 2px;
			background-image: url("../../img/share/icon/icon-arrow-bottom-long.svg");
			background-repeat: no-repeat;
			height: 43px;
			width: 12px;
		}

		&:last-child {
			margin-bottom: 0;
			min-height: inherit;
			&::after {
				display: none;
			}
		}
	}
}


.list-stay-label{
	margin-bottom: 8px;
	&.mb-small {
		margin-bottom: 4px;
	}
	.list-stay-label-list {
		display: flex;
		flex-wrap: wrap;
		.list-stay-label-list-item {
			margin-bottom: 6px;
			position: relative;
			margin-right: 6px;
			padding: 4px 4px;
			line-height: 1;
			font-size: 10px;
			font-weight: bold;
			background-color: #F2F4F8;
			color: #455873;
			border-radius: 4px;
			&:last-child {
				margin-right: 0;
			}
		}
	}

	&.md-large {
		.list-stay-label-list {
			.list-stay-label-list-item {
				@media(min-width:992px) {
					font-size: 12px;
				}
			}
		}
	}
}

.list-stay-area-list {
	.list-stay-area-list-item {
		font-size: 12px;
		color: #455873;
	}
}


//行単位で背景色を交互にする（客室詳細で利用）
.list-alternating {
	display: flex;
	flex-wrap: wrap;
	
	& > li {
		width: 33.33%;
		min-height: 65px;
		padding: 12px;
		font-size: 12px;
		color: #455873;
		background: #F2F4F8;
		display: flex;
		align-items: center;
		position: relative;
		@media(min-width: 992px) {
			width: 25%;
			font-size: 14px;
		}

		&::before {
			content: "";
			display: block;
			width: 1px;
			height: 100%;
			background: rgba(#0F294D, 0.1);
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			transform: translateX(50%);
		}

		//背景色
		&:nth-of-type(6n - 2),
		&:nth-of-type(6n - 1),
		&:nth-of-type(6n) {
			background: #fff;
			@media(min-width: 992px) {
				background: #F2F4F8;
			}
		}
		&:nth-of-type(8n - 3),
		&:nth-of-type(8n - 2),
		&:nth-of-type(8n - 1),
		&:nth-of-type(8n) {
			@media(min-width: 992px) {
				background: #fff;
			}
		}

		//ボーダー
		&:nth-of-type(3n) {
			&::before {
				content: none;
				@media(min-width: 992px) {
					content: "";
				}
			}
		}
		&:nth-of-type(4n) {
			&::before {
				@media(min-width: 992px) {
					content: none;
				}
			}
		}

	}
}



//------------------------------------------------
// モーダル内のページ内リンクリスト
//------------------------------------------------
.list-modal-schedule {
	.list-modal-schedule-item {
		border-bottom: 1px solid #ddd;	
		&:last-child {
			border-bottom: none;
		}
		.list-modal-schedule-item-link {
			position: relative;
			display: inline-block;
			color: #fff;
			padding-left: 1em;
			padding-top: 10px;
			padding-bottom: 10px;
			text-decoration: none;
			transition: .3s;
			font-size: 12px;
			font-weight: bold;
			&:before {
				content: "";
				position: absolute;
				left:0;
				top: 50%;
				transform: translateY(-50%);
				width: .7em;
				height: .8em;
				background-image: url("../../img/share/icon/icon_arrow_right_small.svg");
				background-repeat: no-repeat;
				background-size: contain;
			}
			@media(min-width: 992px) {
				font-size: 14px;
				&:hover {
					opacity: .6;
					transition: .3s;
				}
			}
		}
	}
}


//------------------------------------------------
// カウンター
//------------------------------------------------
.list-counter {
	
	.list-counter-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 18px 8px;
		border-bottom: 1px solid rgba(#0F294D, 0.1);
		@media(min-width: 992px) {
			padding: 24px 8px;
		}

		&:first-of-type {
			padding-top: 0;
			@media(min-width: 992px) {
				padding-top: 0;
			}
		}
	}
	.list-counter-item-title {
		font-size: 16px;
		font-weight: bold;
		color: #0F294D;
		@media(min-width: 992px) {
			font-size: 18px;
		}
	}
	.list-conter-item-contents {
		flex: 0 0 auto;
		margin-left: 12px;
	}
}