/*----------------------------------------------------
　コンポーネント：モーダル
----------------------------------------------------*/
.modal {
    position: relative;
    width: 100%;
    max-width: 910px;
    margin: auto;
}
//モーダル：固定見出しあり
.modal-fixed {
    // padding: 16px;
    border-radius: 4px;
    background-color: #fff;
    overflow: auto;
    height: 100vh;

    &.light-gray {
        background-color: #F2F4F8;
    }

    &.bgc-gray {
        background-color: #F2F4F8;
    }
}
.modal-fixed {
    position: relative;
    .modal-fixed-heading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        width: 100%;
        max-width: 910px;
        margin: auto;
        padding-left: 60px;  //戻るアイコン用にスペース
        padding-right: 60px;  //閉じるアイコン用スペース
        background-color: #fff;
        box-shadow: 2px 2px 7px rgba(0,0,0,.2);
        height: 60px;

        &.no-shadow {
            box-shadow: none;
            border-bottom: 1px solid rgba(#0F294D,.1);
        }
        .modal-fixed-heading-text {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            font-size: 16px;
            font-weight: bold;
            color: #0F294D;
            height: 100%;
            @media(min-width: 992px) {
                // height: 60px;
                font-size: 18px;
            }
        }
        .modal-fixed-heading-back {
            position: absolute;
            left: 9px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .modal-fixed-content {
        padding-top: 60px;
        padding-bottom: 170px;
        @media(min-width: 992px) {
            // padding-top: 60px;
            padding-bottom: 155px;
        }

        &.pb-small {
            padding-bottom: 24px;
            @media(min-width: 992px) {
                padding-bottom: 32px;
            }
        }

        &.pb-medium {
            padding-bottom: 50px;
            @media(min-width: 992px) {
                padding-bottom: 100px;
            }
        }
    }

    .modal-fixed-content-inner {
        padding: 16px 16px 40px;
        color: #0F294D;
        @media(min-width: 992px) {
            padding: 10px 32px 40px 40px;
        }

        &.small {
            padding: 24px 8px;
            @media(min-width: 992px) {
                padding: 40px;
            }
        }

        &.no-padding {
            padding-left: 0;
            padding-right: 0;
            @media(min-width: 992px) {
                padding-left: 0;
                padding-right: 0
            }
        }
    }

    .modal-fixed-content-item {
        @media(min-width: 992px) {
            display: flex;
        }
        .modal-fixed-content-item-carousel {
            padding: 0;
            flex: 1;
            @media(min-width: 992px) {
                padding: 40px 16px 0 40px;
                width: 50%;
            }
        }
        .modal-fixed-content-item-overview {
            padding: 0 16px;
            @media(min-width: 992px) {
                padding: 40px 40px 0 16px;
                width: 50%;
            }
        }
        .modal-fixed-content-item-heading {
            padding: 0 16px 8px;
            font-weight: bold;
            font-size: 18px;
            color: #0F294D;
            @media(min-width: 992px) {
                display: flex;
                flex: 1;
                align-items: center;
                padding: 40px 40px 40px 16px;
                font-size: 20px;
            }
        }
    }

    .modal-fixed-conten-detail {
        padding: 16px;
        @media(min-width: 992px) {
            padding: 16px 40px 32px; 
        }
    }

    .modal-fixed-close {
        position: absolute;
        top: 0;
        right: 0;
        height: 50px;
        .modal-fixed-close-button {
            position: relative;
            display: inline-block;
            min-width: 46px;
            margin-bottom: 0;
            font-size: 10px;
            font-weight: bold;
            color: #0F294D;
            // @media(min-width: 992px) {
            //     font-size: 12px;
            // }
        }
        .modal-fixed-close-button-icon {
            display: block;
            position: relative;
            height: 24px;
            // @media(min-width: 992px) {
            //     height: 28px;
            // }
            &::before {
                content: "";
                position: absolute;
                right: 50%;
                top: 4px;
                transform: rotate(45deg) translateX(-50%);
                width: 2px;
                height: 20px;
                background-color: #0F294D;
                // @media(min-width: 992px) {
                //     height: 24px;
                // }
            }
            &::after {
                content: "";
                position: absolute;
                right: 50%;
                top: 3px;
                transform: rotate(-45deg) translateX(-50%);
                width: 2px;
                height: 20px;
                background-color: #0F294D;
                // @media(min-width: 992px) {
                //     height: 24px;
                // }
            }
        }
    }

    .modal-fixed-bottom {
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 60; //loading-overlayはz-index:50;
        width: 100%;
        max-width: 910px;
        margin: auto;
        height: 96px;
        background-color: #fff;
        box-shadow: 2px 2px 7px rgba(0,0,0,.2);
        padding: 16px 16px 30px;
        @media(min-width: 992px) {
            height: 107px;
            padding: 24px 40px 32px;
        }

        &.variable {
            height: auto;
        }

        .button-medium-light_blue {
            font-size: 18px;
        }
        .button-medium-orange {
            font-size: 18px;
        }
    }
    .modal-fixed-bottom-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .modal-fixed-bottom-content-text {
        @media(min-width: 992px) {
            display: flex;
            align-items: center;
            flex: 1;
        }
    }

    .modal-fixed-bottom-2column-wrap{
        height: auto;
    }
    .modal-fixed-bottom-2column{
        display: flex;
        align-items: center;
        width: 100%;
        @media(min-width: 992px) {
            width: calc(100% + 16px);
            margin-left: -8px;
            margin-right: -8px;
        }

        .button-medium-light_blue{
            height: auto;
            line-height: 1.25;
            padding: 10px 15px;
        }
        .button-medium-orange{
            background-color: #F85400;
            font-size: 18px;
            height: auto;
            line-height: 1.25;
            padding: 10px 15px;
        }
    }
}


//モーダル画面一部
.modal-simple {
    display: flex;
    justify-content: center;
    align-items: center;

    &.dark {
        .modal-simple-content {
            background-color: #4C4D4E;
        }
    }
    .modal-simple-content {
        position: relative;
        width: 100%;
        margin-left: 24px;
        margin-right: 24px;
        padding: 24px;
        max-width: 350px;
        background-color: #fff;
        border-radius: 4px;
        &.pt-0 {
            padding-top: 0;
        }
        &.pb-0 {
            padding-bottom: 0;
        }
        .modal-simple-content-close {
            position: absolute;
            right: -15px;
            top: -15px;
            cursor: pointer;
            transition: .3s;
            &:hover {
                transition: .3s;
                opacity: .7;
            }
            
        }
    }
}


// モーダル用ナビゲーション
.modal-navi{
    position: sticky;
    top:60px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    box-shadow: 2px 2px 7px rgba(0,0,0,.2);
    z-index: 10;
    background-color: #fff;
    @media(min-width: 992px) {
        top: 60px;
    }
    
    .modal-navi-item{
        position: relative;
        font-size: 14px;
        font-weight: bold;
        color: #1A3268;
        text-align: center;
        width: 50%;
        height: 100%;
        padding: 0;
        overflow: hidden;
        &::before{
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 2px;
            height: 15px;
            background-color: #E2E4EA;
            margin: auto;
        }

        &:last-child {
            &::before {
                display: none;
            }
        }

        &::after{
            content: "";
            position: absolute;
            right: 100%;
            width: 100%;
            bottom: 0;
            transition: .3s;
            border-bottom: 2px solid #1A3268;
        }

        &:hover{
            background-color: #fff;
            transition: .3s;

            &::after{
                content: "";
                right: 0;
                transition: .3s;
            }
        }


        .MuiButton-label{
            display: flex;
            flex-direction: column;
        }

        .modal-navi-item-arrow{
            width: 9px;
        }
    }
}


// クーポン画面用モーダル
.modal-coupon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding: 24px;

    .box-light-gray {
        padding: 12px 10px 10px;

        @media (min-width: 992px) {
            padding: 16px 20px 20px;
        }
    }

    .box-white {
        padding: 10px 16px;

        @media (min-width: 992px) {
            padding: 12px 16px;
        }

        // クーポン不適用時
        &.non-applicable {
            padding: 12px;

            @media (min-width: 992px) {
                padding: 16px;
            }
        }
    }

    .box-red {
        padding: 10px;

        @media (min-width: 992px) {
            padding: 14px 20px;
        }
    }
}

.modal-coupon-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;

    // 閉じるボタンの高さ15px分、モーダルが下へずれるので補正させる
    margin-bottom: 15px;

    &.is-scroll {
        height: 100%;
    }

    .modal-coupon-close-btn {
        position: relative;
        top: 4px;
        left: calc(100% - 24px);
        z-index: 1;
        width: 30px;
        height: 15px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            opacity: 0.7;
            transition: 0.3s;
        }
    }
}

.modal-coupon-content {
    position: relative;
    width: 100%;
    max-width: 342px;
    max-height: 100%;
    padding-top: 24px;
    // スクロールバーの位置を考慮
    padding-right: 6px;
    padding-bottom: 24px;
    padding-left: 16px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 5px;

    @media (min-width: 390px) {
        width: 100vw;
    }

    @media (min-width: 992px) {
        width: 440px;
        max-width: 440px;
        padding-top: 32px;
        // スクロールバーの位置を考慮
        padding-right: 10px;
        padding-bottom: 32px;
        padding-left: 24px;
    }
}

// 閉じるボタン
.modal-coupon-close-text {
    font-size: 14px;
    color: #0169d8;
    text-decoration: underline;

    @media (min-width: 992px) {
        opacity: 1;
        transition: 0.3s;

        &:hover {
            opacity: 0.7;
        }
    }
}